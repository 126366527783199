import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../shared/component/menu/menu.component';
import { SearchBarComponent } from '../shared/component/search-bar/search-bar.component';
import { AuthService } from '../shared/services/auth.service';
import { UserLoginResponse } from '../shared/services/responses/auth/user-login.response';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { PerfilResponse } from '../shared/services/responses/pessoa/perfil.response';
import { IAInsightsResponse } from '../shared/services/responses/pessoa/ia-insights.response';
import { DefaultColorEnum } from '../shared/enum/default-color.enum';
import { AlertService, AlertType } from '../shared/services/alert.service';
import { PessoaService } from '../shared/services/API/pessoa/pessoa.service';
import { Perfil } from '../shared/services/models/pessoa/perfil.model';
import { Router } from '@angular/router';
import { SkeletonComponent } from '../shared/skeleton/skeleton.component';
import { AdvancedTableComponent } from '../shared/component/advanced-table/advanced-table.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    SearchBarComponent,
    MatCardModule,
    MatIconModule,
    CommonModule,
    SkeletonComponent,
    AdvancedTableComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent implements OnInit {
  public userLoginResponse: UserLoginResponse;
  public listPerfil: Perfil[];
  public suggestionCards: IAInsightsResponse[];
  public modules: any[] = [];
  public accesColor: string = DefaultColorEnum.Acesso;
  public unnaccesColor: string = DefaultColorEnum.NaoAcesso;
  public isLoading: boolean = true;
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public pessoaService: PessoaService,
    private router: Router
  ) {}
  public color: string = DefaultColorEnum.Cor;

  tableData = [
    {
      id: 1,
      name: 'João Silva',
      age: 28,
      email: 'joao@gmail.com',
      status: 'Ativo',
    },
    {
      id: 2,
      name: 'Maria Oliveira',
      age: 34,
      email: 'maria@gmail.com',
      status: 'Inativo',
    },
    {
      id: 3,
      name: 'Carlos Pereira',
      age: 45,
      email: 'carlos@gmail.com',
      status: 'Ativo',
    },
    {
      id: 4,
      name: 'Ana Souza',
      age: 25,
      email: 'ana@gmail.com',
      status: 'Atenção',
    },
  ];

  tableColumns = [
    { key: 'id', label: 'ID', sortable: true, filterable: false },
    { key: 'name', label: 'Nome', sortable: false, filterable: true },
    { key: 'age', label: 'Idade', sortable: true, filterable: false },
    { key: 'email', label: 'Email', sortable: false, filterable: true },
    { key: 'status', label: 'Status', sortable: false, filterable: true },
  ];

  ngOnInit(): void {
    this.userLoginResponse = this.authService.getUserInfos();
    if (this.userLoginResponse.empresaLogada?.corSistema) {
      this.color = this.userLoginResponse.empresaLogada?.corSistema;
      this.changeColor();
    }
    this.getPerfil();
  }

  onCardClick(card: Perfil): void {
    let filteredModule = this.userLoginResponse.empresaLogada.listIdPerfil.find(
      (module) => module === card.idPerfil
    );
    if (filteredModule) {
      this.router.navigate([card.redirect]);
    } else {
      this.alertService.show(
        'Erro!',
        'Você não possui acesso a este perfil',
        AlertType.error
      );
    }
  }

  onSuggestionCardClick(card: any): void {}

  getPerfil() {
    if (this.userLoginResponse.listPerfil) {
      this.listPerfil = this.userLoginResponse.listPerfil;
      this.listPerfil = this.userLoginResponse.listPerfil.filter(
        (perfil) =>
          this.userLoginResponse.empresaLogada.listIdPerfil.includes(
            perfil.idPerfil
          ) && perfil.exibirTelaInicial
      );
      this.cardLogical();
      this.isLoading = false;
    } else {
      this.pessoaService.GetPerfil().subscribe({
        next: (response: PerfilResponse) => {
          if (response.isError) {
            this.alertService.show(
              'Erro inesperado',
              response.errorDescription,
              AlertType.error
            );
            return;
          }
          this.listPerfil = response.listPerfil;
          this.listPerfil = response.listPerfil.filter(
            (perfil) =>
              this.userLoginResponse.empresaLogada.listIdPerfil.includes(
                perfil.idPerfil
              ) && perfil.exibirTelaInicial
          );
          this.cardLogical();
          this.userLoginResponse.listPerfil = response.listPerfil;
          this.authService.updateToken(this.userLoginResponse);
          this.isLoading = false;
        },
        error: (error) => {
          this.alertService.show('Erro inesperado', error, AlertType.error);
          this.isLoading = false;
        },
      });
    }
  }

  cardLogical() {
    this.modules = this.listPerfil.map((modulo) => {
      return {
        ...modulo,
        corDeFundo: modulo.corInvertida ? this.color : this.accesColor,
      };
    });
  }

  changeColor() {
    document.documentElement.style.setProperty('--main-color', this.color);
  }
}
