import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { RecoverPasswordRequest } from '../../requests/pessoa/recover-password.request';
import { RecoverPasswordResponse } from '../../responses/pessoa/recover-password.response';
import { ReturnStruct } from '../../structs/return.struct';
import { ResetPasswordRequest } from '../../requests/pessoa/reset-password.request';

@Injectable({
  providedIn: 'root'
})
export class PasswordChangeService extends CommonService {

  constructor(
    private httpClient: HttpClient,
  ) {
    super();
  }

  public post(recoverPasswordRequest: RecoverPasswordRequest): Observable<RecoverPasswordResponse> {
    return this.httpClient.post<RecoverPasswordResponse>(environment.urlApiPessoa + "PasswordChange", recoverPasswordRequest)
      .pipe(
        catchError(this.handleError)
      )
  }

  public get(token: string): Observable<ReturnStruct> {
    let url = environment.urlApiPessoa + `PasswordChange/token/${token}`;

    return this.httpClient.get<RecoverPasswordResponse>(url)
      .pipe(
        catchError(this.handleError)
      )
  }

  public put(resetPasswordRequest: ResetPasswordRequest): Observable<ReturnStruct> {
    return this.httpClient.put<ReturnStruct>(environment.urlApiPessoa + `PasswordChange`, resetPasswordRequest)
      .pipe(
        catchError(this.handleError)
      )
  }

}
