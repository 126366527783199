<div class="container container-auth">
    <div class="vertical-aling-out">
        <div class="vertical-aling-inner">
            <img class="logo-auth" src="assets/images/atsone.png">
            @if(_verifyTokenReturnStruct != undefined){
            @if(_verifyTokenReturnStruct.isError == false){
            <form class="form" [formGroup]="model" (ngSubmit)="post()" class="text-center">
                <h1>Redefinir senha</h1>
                <p>
                    Insira sua nova senha.
                </p>
                <mat-form-field appearance="outline">
                    <mat-label>Senha</mat-label>
                    <input matInput formControlName="password1" type="password">
                    @if (model.get('password1')?.invalid) {
                    <mat-error>Informe o seu e-mail cadastrado</mat-error>
                    }
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Repita sua senha</mat-label>
                    <input matInput formControlName="password2" type="password">
                    @if (model.get('password2')?.invalid) {
                    <mat-error>Informe o seu e-mail cadastrado</mat-error>
                    }
                </mat-form-field>

                <button type="submit" mat-flat-button class="btn-primary button-big button-login">
                    @if (isLoading == true) {
                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                    }
                    @else{
                    Redefinir Senha
                    }
                </button>
                <button type="button" mat-button class="button-big button-login"
                    (click)="redirectLogin()">Voltar</button>
            </form>
            }
            @else {
            <div class="text-center">
                <h1>Link invalido</h1>
                <p>{{_verifyTokenReturnStruct.errorDescription}}</p>
                <button type="button" mat-button class="button-big button-login"
                    (click)="redirectLogin()">Voltar</button>
            </div>

            }
            }
            @else{
            <div class="text-center">
                <mat-spinner [diameter]="60"></mat-spinner>
            </div>
            }
        </div>
    </div>
</div>