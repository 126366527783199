import { Component, OnInit } from '@angular/core';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Editor, NgxEditorModule, Toolbar, Validators } from 'ngx-editor';
import { MatSelectModule } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { SectionProductImagesComponent } from './section-product-images/section-product-images.component';
import { EstoqueService } from '../../../shared/services/API/estoque/estoque.service';
import { ProdutoResponse } from '../../../shared/services/responses/estoque/produto.response';
import { ProdutoBaseRequest } from '../../../shared/services/requests/estoque/produto-base.request';
import { ProdutoBaseResponse } from '../../../shared/services/responses/estoque/produto-base.response';
import { NcmSearchStruct } from '../../../shared/services/structs/estoque/ncm-search-struct';
import { NcmSearchResponse } from '../../../shared/services/responses/estoque/ncm-search.response';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CameraDialogComponent } from './camera-dialog/camera-dialog.component';
import { RoutesService } from '../../../routes.service';
import { SubheaderComponent } from '../../../shared/subheader/subheader.component';
import { PessoaService } from '../../../shared/services/API/pessoa/pessoa.service';
import { ListFornecedorResponse } from '../../../shared/services/responses/pessoa/list-fornecedor.response';
import { FornecedorPessoaStruct } from '../../../shared/services/structs/pessoa/fornecedor-pessoa.struct';
import { Observable } from 'rxjs';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { AuthService } from '../../../shared/services/auth.service';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { SupplierRegisterModalComponent } from '../../../shared/component/supplier-register-modal/supplier-register-modal.component';
import { SectionProductGridComponent } from "./section-product-grid/section-product-grid.component";

@Component({
  selector: 'app-inventory-register',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    CommonModule,
    MatSlideToggleModule,
    NgxEditorModule,
    MatSelectModule,
    SectionProductImagesComponent,
    MatAutocompleteModule,
    SubheaderComponent,
    ReactiveFormsModule,
    SectionProductGridComponent
],
  templateUrl: './inventory-register.component.html',
  styleUrl: './inventory-register.component.css',
})
export class InventoryRegisterComponent implements OnInit {
  searchControl: FormControl;
  filteredFornecedores: any[] = [];
  public userLoginResponse: UserLoginResponse;
  constructor(
    private router: Router,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private estoqueService: EstoqueService,
    private pessoaService: PessoaService,
    public dialog: MatDialog,
    private routesService: RoutesService,
    private authService: AuthService
  ) {
    let idProdutoUrl: string = this.activatedRoute.snapshot.params['idProduto'];
    if (idProdutoUrl) {
      this._idProduto = Number(idProdutoUrl);
      this.loadProduct();
    } else {
      this._produtoResponse = new ProdutoResponse();
    }
  }

  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  public isLoading: boolean = false;
  public model: FormGroup;
  public _idProduto: number;
  public _produtoResponse: ProdutoResponse;
  public _listNcmSearchStruct: NcmSearchStruct[] = [];
  private debounceTimeout: any;
  public nameProfile: string = 'Produto';
  public iconProfile: string = 'inventory';
  public listFornecedorPessoaStruct: FornecedorPessoaStruct[];
  public filteredOptions: Observable<FornecedorPessoaStruct[]>;
  public selectedOption: any;
  public profile: number = DefaultProfilesEnum.Produto;
  public isViewSupply: boolean = false;
  public isSupplyRegister: boolean = false;
  public supplyProfile: number = DefaultProfilesEnum.Fornecedor;
  public color: string = DefaultColorEnum.Cor;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      productName: ['', [Validators.required]],
      productCode: [''],
      ean: [''],
      nmc: [''],
      htmlDescription: ['', [Validators.required]],
      isImmediateDelivery: [true],
      idCollection: [''],
      idCategory: [''],
      idFornecedor: [null],
      searchTerm: [null]
    });

    this.userLoginResponse = this.authService.getUserInfos();

    this.authService.permissao(this.profile, PapelEnum.lerGravar);

    if (this.userLoginResponse.empresaLogada?.corSistema) {
      this.color = this.userLoginResponse.empresaLogada?.corSistema;
      this.changeColor();
    }

    this.nameProfile =
      this.userLoginResponse.listPerfil.find((x) => x.idPerfil == this.profile)
        ?.nomePerfil ?? this.nameProfile;
    this.iconProfile =
      this.userLoginResponse.listPerfil.find((x) => x.idPerfil == this.profile)
        ?.googleIcone ?? this.iconProfile;

    this.searchControl = new FormControl('');

    this.searchControl.valueChanges.subscribe(value => {
      this.filterFornecedores(value);
    });

    this.editor = new Editor();

    let idProduto: number = this.activatedRoute.snapshot.params['id'];

    if (idProduto) {
      this._idProduto = idProduto;
      this.loadProduct();
    }

    this.isViewSupply = this.authService.verify(this.supplyProfile, PapelEnum.ler);
    this.isSupplyRegister = this.authService.verify(this.supplyProfile, PapelEnum.lerGravar);

    if (this.isViewSupply)
      this.getSupplier();

  }

  loadProduct() {
    this.estoqueService.GetProduto(this._idProduto).subscribe({
      next: (produtoResponse: ProdutoResponse) => {
        this.isLoading = false;
        if (produtoResponse.isError) {
          this.alertService.show(
            'Erro inesperado',
            produtoResponse.errorDescription,
            AlertType.error
          );
          return;
        }

        this._produtoResponse = produtoResponse;

        this.model
          .get('productName')
          ?.setValue(produtoResponse.produtoStruct.produto.nome);
        this.model
          .get('ean')
          ?.setValue(produtoResponse.produtoStruct.produto.ean);
        this.model
          .get('nmc')
          ?.setValue(produtoResponse.produtoStruct.produto.nmc);
        this.model
          .get('productCode')
          ?.setValue(produtoResponse.produtoStruct.produto.codProduto);
        this.model
          .get('htmlDescription')
          ?.setValue(produtoResponse.produtoStruct.produto.descricao);
        this.model
          .get('idFornecedor')
          ?.setValue(produtoResponse.produtoStruct.produto.idFornecedor);
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  getNcmValues(filterText: any) {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      if (filterText) {
        const lowerFilter = filterText.toLowerCase();
        this.estoqueService.SearchNcm(lowerFilter).subscribe({
          next: (ncmSearchResponse: NcmSearchResponse) => {
            this.isLoading = false;
            if (ncmSearchResponse.isError) {
              this.alertService.show(
                'Erro inesperado',
                ncmSearchResponse.errorDescription,
                AlertType.error
              );
              return;
            }
            this._listNcmSearchStruct = ncmSearchResponse.listNcmSearchStruct;
          },
          error: (error) => {
            this.isLoading = false;
            this.alertService.show('Erro inesperado', error, AlertType.error);
          },
        });
      }
    }, 300);
  }

  selectNcmValue(item: NcmSearchStruct) {
    this.model.get('nmc')?.setValue(item.codigo);
  }

  saveProductBase() {
    let productBaseRequest: ProdutoBaseRequest = new ProdutoBaseRequest();

    if (this._idProduto) productBaseRequest.idProduto = this._idProduto;

    productBaseRequest.nome = this.model.get('productName')?.value;
    productBaseRequest.codProduto = this.model.get('productCode')?.value;
    productBaseRequest.ncm = this.model.get('nmc')?.value;
    productBaseRequest.ean = this.model.get('ean')?.value;
    productBaseRequest.codProduto = this.model.get('productCode')?.value;
    productBaseRequest.descricao = this.model.get('htmlDescription')?.value;
    productBaseRequest.idFornecedor = this.model.get('idFornecedor')?.value;

    this.isLoading = true;

    this.estoqueService.CreateProdutoBase(productBaseRequest).subscribe({
      next: (productBaseResponse: ProdutoBaseResponse) => {
        this.isLoading = false;
        if (productBaseResponse.isError) {
          this.alertService.show(
            'Erro inesperado',
            productBaseResponse.errorDescription,
            AlertType.error
          );
          return;
        }

        if (this._idProduto) {
          this.alertService.show(
            'Sucesso',
            'Dados base do produto foi alterado com sucesso',
            AlertType.success
          );
          this.router.navigate([
            this.routesService.LIST_INVENTORY,
            { idProduto: productBaseResponse.idProduto },
          ]);
        }

        else {
          this.alertService.show(
            'Sucesso',
            'Produto criado com sucesso',
            AlertType.success
          );
          this._idProduto = productBaseResponse.idProduto
            ? productBaseResponse.idProduto
            : 0;
          this.router.navigate([
            '/inventory/inventory-register',
            { idProduto: productBaseResponse.idProduto },
          ]);
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  openCamera() {
    const dialogRef = this.dialog.open(CameraDialogComponent, {
      width: '400px',
      height: '400px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.model.get('ean')?.setValue(result);
      }
    });
  }

  filterFornecedores(searchTerm: string): void {
    if (!searchTerm) {
      this.filteredFornecedores = this.listFornecedorPessoaStruct;
    } else {
      this.filteredFornecedores = this.listFornecedorPessoaStruct.filter(fornecedor =>
        fornecedor.pessoa.nome.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  }

  onSelectChange(event: any) {
    this.selectedOption = this.listFornecedorPessoaStruct.find(fornecedor => fornecedor.fornecedor.idFornecedor === event.value);
  }

  getSupplier() {
    this.isLoading = true;
    this.model.get('idFornecedor')?.disable();
    this.pessoaService.searchSupplier().subscribe({
      next: (response: ListFornecedorResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.model.get('idFornecedor')?.enable();
          this.isLoading = false;
          return;
        }
        this.listFornecedorPessoaStruct = response.listFornecedorPessoaStruct;
        this.filteredFornecedores = this.listFornecedorPessoaStruct;
        this.isLoading = false;
        this.model.get('idFornecedor')?.enable();
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar lista de fornecedores',
          AlertType.error
        );
        this.isLoading = false;
        this.model.get('idFornecedor')?.enable();
        return;
      },
    });
  }

  changeColor() {
    document.documentElement.style.setProperty('--main-color', this.color);
  }

  openSupplyModal() {
    const modalRef = this.dialog.open(SupplierRegisterModalComponent, {
      hasBackdrop: true,
      disableClose: false,
    });

    modalRef.afterClosed().subscribe(
      (result: { confirm: boolean; }) => {
        if (result && result.confirm) {
          this.getSupplier();
        }
        else {
          this.isLoading = false;
          return;
        }
      }
    );
  }
}
