import { StatusStruct } from "./status.struct";

export class ListStatusStruct {
  listStatus: StatusStruct[];

  constructor() {
    this.listStatus = [
      new StatusStruct(1, 'Status'),
      new StatusStruct(2, 'Ativo'),
      new StatusStruct(3, 'Inativo')
    ]
  }
}