import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { PessoaService } from '../../../shared/services/API/pessoa/pessoa.service';
import { AuthService } from '../../../shared/services/auth.service';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { MatIconModule } from '@angular/material/icon';
import { UtilService } from '../../../shared/services/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaskService } from '../../../shared/services/mask.service';
import { ClienteRequest } from '../../../shared/services/requests/pessoa/cliente.request';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { ClienteResponse } from '../../../shared/services/responses/pessoa/cliente.response';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';
import { SubheaderComponent } from '../../../shared/subheader/subheader.component';
import { RoutesService } from '../../../routes.service';
import { ClienteStruct } from '../../../shared/services/structs/pessoa/cliente.struct';
import { MatSelectModule } from '@angular/material/select';
import { TipoEClienteEnum } from '../../../shared/enum/Pessoa/tipo-cliente.enum';
import { ListClienteStructResponse } from '../../../shared/services/responses/pessoa/list-cliente-struct.response';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-client-register',
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    SubheaderComponent,
    MatSelectModule,
    MatCheckboxModule,
  ],
  templateUrl: './client-register.component.html',
  styleUrl: './client-register.component.css',
})
export class ClientRegisterComponent implements OnInit {
  searchControl: FormControl;
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  public isViewFinancialFields: boolean = false;
  public isWriteFinancialFields: boolean = false;
  private cepTimeout: any;
  public masks: any;
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public pessoaService: PessoaService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private router: Router,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    private routesService: RoutesService
  ) {}
  public model: FormGroup;
  public requestUpdate: ClienteRequest = new ClienteRequest();
  public color: string = DefaultColorEnum.Cor;
  public _idClient: number | null = null;
  public dataList: any[];
  public nameProfile: string = 'Cliente';
  public iconProfile: string = 'person';
  public profile: number = DefaultProfilesEnum.Cliente;
  public financialProfile: number = DefaultProfilesEnum.Financeiro;
  public isDependent: boolean = false;
  public listClients: ClienteStruct[] = [];
  public filteredClientes: ClienteStruct[] = [];
  public selectedOption: any;
  @ViewChild('numeroEndereco') numeroEndereco: ElementRef;
  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      nome: ['', Validators.required],
      razaoSocial: [''],
      email: ['', Validators.required],
      cpfCnpj: ['', Validators.required],
      telefone: [''],
      telefone2: [''],
      nascimento: [''],
      cep: [''],
      logradouro: [''],
      cidade: [''],
      numero: [''],
      bairro: [''],
      complemento: [''],
      localidade: [''],
      uf: [''],
      pais: [''],
      limite: [''],
      renda: [''],
      dependente: [''],
      idClienteTitular: [null]
    });
    this.userLoginResponse = this.authService.getUserInfos();

    this.authService.permissao(this.profile, PapelEnum.lerGravar);

    this.searchControl = new FormControl('');

    this.searchControl.valueChanges.subscribe(value => {
      this.filterClientes(value);
    });

    this.isViewFinancialFields = this.authService.verify(this.financialProfile, PapelEnum.ler);
    this.isWriteFinancialFields = this.authService.verify(this.financialProfile, PapelEnum.lerGravar);

    if (this.userLoginResponse.empresaLogada?.corSistema) {
      this.color = this.userLoginResponse.empresaLogada?.corSistema;
      this.changeColor();
    }
    this.nameProfile =
      this.userLoginResponse.listPerfil.find((x) => x.idPerfil == this.profile)
        ?.nomePerfil ?? this.nameProfile;
    this.iconProfile =
      this.userLoginResponse.listPerfil.find((x) => x.idPerfil == this.profile)
        ?.googleIcone ?? this.iconProfile;
    let idClient: number = this.activatedRoute.snapshot.params['idCliente'];
    if (idClient) {
      this._idClient = Number(idClient);
      this.model.disable();
      this.getClient();
    }
    this.getListClient();
    this.isLoading = false;
  }

  changeColor() {
    document.documentElement.style.setProperty('--main-color', this.color);
  }

  submit() {
    this.isLoading = true;
    if (this.model.invalid) {
      this.alertService.show(
        'Erro!',
        'Preencha os campos obrigatórios',
        AlertType.error
      );
      this.isLoading = false;
      return;
    } else if (this._idClient) {
      if (this.model.get('nome')?.value)
        this.requestUpdate.nome = this.model.get('nome')?.value;
      if (this.model.get('razaoSocial')?.value)
        this.requestUpdate.razaoSocial = this.model.get('razaoSocial')?.value;
      if (this.model.get('email')?.value)
        this.requestUpdate.email = this.model.get('email')?.value;
      if (this.model.get('cpfCnpj')?.value)
        this.requestUpdate.cpfCnpj = this.model.get('cpfCnpj')?.value;
      if (this.model.get('telefone')?.value)
        this.requestUpdate.telefone = this.model.get('telefone')?.value;
      if (this.model.get('telefone2')?.value)
        this.requestUpdate.telefone2 = this.model.get('telefone2')?.value;
      if (this.model.get('nascimento')?.value)
        this.requestUpdate.nascimento = this.model.get('nascimento')?.value;
      if (this.model.get('latitude')?.value)
        this.requestUpdate.latitude = this.model.get('latitude')?.value;
      if (this.model.get('longitude')?.value)
        this.requestUpdate.longitude = this.model.get('longitude')?.value;
      if (this.model.get('cep')?.value)
        this.requestUpdate.cep = this.model.get('cep')?.value;
      if (this.model.get('logradouro')?.value)
        this.requestUpdate.logradouro = this.model.get('logradouro')?.value;
      if (this.model.get('cidade')?.value)
        this.requestUpdate.cidade = this.model.get('cidade')?.value;
      if (this.model.get('numero')?.value)
        this.requestUpdate.numero = this.model.get('numero')?.value;
      if (this.model.get('uf')?.value)
        this.requestUpdate.estado = this.model.get('uf')?.value;
      if (this.model.get('rua')?.value)
        this.requestUpdate.rua = this.model.get('rua')?.value;
      if (this.model.get('bairro')?.value)
        this.requestUpdate.bairro = this.model.get('bairro')?.value;
      if (this.model.get('complemento')?.value)
        this.requestUpdate.complemento = this.model.get('complemento')?.value;
      if (this.model.get('pais')?.value)
        this.requestUpdate.pais = this.model.get('pais')?.value;
      if (this.model.get('limite')?.value)
        this.requestUpdate.limiteCredito = this.model.get('limite')?.value;
      if (this.model.get('renda')?.value)
        this.requestUpdate.renda = this.model.get('renda')?.value;

      if(this.isDependent)
      {
        this.requestUpdate.idTipoCliente = TipoEClienteEnum.Dependente;
        if (this.model.get('idClienteTitular')?.value)
          this.requestUpdate.idClienteTitular = this.model.get('idClienteTitular')?.value;
      }
      else
      {
        this.requestUpdate.idTipoCliente = TipoEClienteEnum.Titular;
      }
      
      this.update();
    } else {
      const clienteRequest = new ClienteRequest();
      if (this.model.get('nome')?.value)
        clienteRequest.nome = this.model.get('nome')?.value;
      if (this.model.get('razaoSocial')?.value)
        clienteRequest.razaoSocial = this.model.get('razaoSocial')?.value;
      if (this.model.get('email')?.value)
        clienteRequest.email = this.model.get('email')?.value;
      if (this.model.get('cpfCnpj')?.value)
        clienteRequest.cpfCnpj = this.model.get('cpfCnpj')?.value;
      if (this.model.get('telefone')?.value)
        clienteRequest.telefone = this.model.get('telefone')?.value;
      if (this.model.get('telefone2')?.value)
        clienteRequest.telefone2 = this.model.get('telefone2')?.value;
      if (this.model.get('nascimento')?.value)
        clienteRequest.nascimento = this.model.get('nascimento')?.value;
      if (this.model.get('latitude')?.value)
        clienteRequest.latitude = this.model.get('latitude')?.value;
      if (this.model.get('longitude')?.value)
        clienteRequest.longitude = this.model.get('longitude')?.value;
      if (this.model.get('cep')?.value)
        clienteRequest.cep = this.model.get('cep')?.value;
      if (this.model.get('logradouro')?.value)
        clienteRequest.logradouro = this.model.get('logradouro')?.value;
      if (this.model.get('cidade')?.value)
        clienteRequest.cidade = this.model.get('cidade')?.value;
      if (this.model.get('numero')?.value)
        clienteRequest.numero = this.model.get('numero')?.value;
      if (this.model.get('uf')?.value)
        clienteRequest.estado = this.model.get('uf')?.value;
      if (this.model.get('rua')?.value)
        clienteRequest.rua = this.model.get('rua')?.value;
      if (this.model.get('complemento')?.value)
        clienteRequest.complemento = this.model.get('complemento')?.value;
      if (this.model.get('bairro')?.value)
        clienteRequest.bairro = this.model.get('bairro')?.value;
      if (this.model.get('pais')?.value)
        clienteRequest.pais = this.model.get('pais')?.value;
      if (this.model.get('limite')?.value)
        clienteRequest.limiteCredito = this.model.get('limite')?.value;
      if (this.model.get('renda')?.value)
        clienteRequest.renda = this.model.get('renda')?.value;

      if(this.isDependent)
        {
          clienteRequest.idClienteTitular = TipoEClienteEnum.Dependente;
          if (this.model.get('idClienteTitular')?.value)
            clienteRequest.idClienteTitular = this.model.get('idClienteTitular')?.value;
        }
        else
        {
          clienteRequest.idClienteTitular = TipoEClienteEnum.Titular;
        }

      this.register(clienteRequest);
    }
  }

  register(client: ClienteRequest) {
    this.pessoaService.clientRegister(client).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Cliente registrado',
          AlertType.success
        );
        this.router.navigate([this.routesService.LIST_CLIENTS]);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  update() {
    this.pessoaService.clientUpdate(this.requestUpdate).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Paciente atualizado',
          AlertType.success
        );
        this.router.navigate([this.routesService.LIST_CLIENTS]);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  getClient() {
    this.pessoaService.searchClientById(Number(this._idClient)).subscribe({
      next: (response: ClienteResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.requestUpdate.idCliente = response.cliente.idCliente;
        this.requestUpdate.idTipoCliente = response.cliente.idTipoCliente;
        this.requestUpdate.renda = response.cliente.renda;
        this.requestUpdate.limiteCredito = response.cliente.limiteCredito;
        this.requestUpdate.idClienteTitular = response.cliente.idClienteTitular;
        this.requestUpdate.idPessoa = response.cliente.idPessoa;
        this.model.get('nome')?.setValue(response.pessoa.nome);
        this.model.get('razaoSocial')?.setValue(response.pessoa.razaoSocial);
        this.model.get('email')?.setValue(response.pessoa.email);
        this.model.get('cpfCnpj')?.setValue(response.pessoa.cpfCnpj);
        this.model.get('telefone')?.setValue(response.pessoa.telefone);
        this.model.get('telefone2')?.setValue(response.pessoa.telefone2);
        if (response.pessoa.nascimento) {
          var date = response.pessoa.nascimento.toString();
          date = date.split('T')[0];
          this.model.get('nascimento')?.setValue(date);
        }
        this.model.get('latitude')?.setValue(response.pessoa.latitude);
        this.model.get('limite')?.setValue(response.cliente.limiteCredito)
        this.model.get('renda')?.setValue(response.cliente.renda)
        this.model.get('longitude')?.setValue(response.pessoa.longitude);
        this.model.get('cep')?.setValue(response.pessoa.cep);
        this.model.get('logradouro')?.setValue(response.pessoa.logradouro);
        this.model.get('cidade')?.setValue(response.pessoa.cidade);
        this.model.get('numero')?.setValue(response.pessoa.numero);
        this.model.get('uf')?.setValue(response.pessoa.estado);
        this.model.get('rua')?.setValue(response.pessoa.logradouro);
        this.model.get('complemento')?.setValue(response.pessoa.complemento);
        this.model.get('bairro')?.setValue(response.pessoa.bairro);
        this.model.get('pais')?.setValue(response.pessoa.pais);
        this.model.enable();
        this.model.get('email')?.disable();
        this.model.get('cpfCnpj')?.disable();
        this.applyMasks(this.model.get('cpfCnpj')?.value);
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar cep',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }

  searchCep(event: any) {
    const cep = this.model.get('cep')?.value;
    if (this.cepTimeout) {
      clearTimeout(this.cepTimeout);
    }
    this.isLoading = true;
    this.model.get('logradouro')?.disable();
    this.model.get('complemento')?.disable();
    this.model.get('bairro')?.disable();
    this.model.get('uf')?.disable();
    this.model.get('pais')?.disable();
    this.model.get('cidade')?.disable();
    this.model.get('numero')?.disable();

    this.cepTimeout = setTimeout(() => {
      this.utilService
        .getAddressByCEP(cep)
        .pipe(
          catchError((error) => {
            this.alertService.show('Erro inesperado', error, AlertType.error);
            this.isLoading = false;
            this.model.get('logradouro')?.enable();
            this.model.get('complemento')?.enable();
            this.model.get('bairro')?.enable();
            this.model.get('uf')?.enable();
            this.model.get('pais')?.enable();
            this.model.get('cidade')?.enable();
            this.model.get('numero')?.enable();
            return of(null);
          })
        )
        .subscribe((data) => {
          if (data) {
            this.model.patchValue({
              logradouro: data.logradouro,
              complemento: data.complemento,
              bairro: data.bairro,
              cidade: data.localidade,
              uf: data.uf,
              pais: 'Brasil',
            });
          }

          this.model.get('logradouro')?.enable();
          this.model.get('complemento')?.enable();
          this.model.get('bairro')?.enable();
          this.model.get('uf')?.enable();
          this.model.get('pais')?.enable();
          this.model.get('cidade')?.enable();
          this.model.get('numero')?.enable();
          this.numeroEndereco.nativeElement.focus();
          this.isLoading = false;
        });
    }, 2000);
  }

  backPage() {
    this.router.navigate([this.routesService.LIST_CLIENTS]);
  }

  onCpfCnpjInput(event: any): void {
    const value = event.target.value.replace(/\D/g, '');
    let formattedValue = '';

    if (value.length < 11) {
      formattedValue = value;
    } else if (value.length == 11) {
      formattedValue = this.maskService.applyMask(value, this.masks.cpf);
    } else if (value.length > 11) {
      formattedValue = this.maskService.applyMask(value, this.masks.cnpj);
    }

    this.model.get('cpfCnpj')?.setValue(formattedValue);
  }

  applyMasks(cpfCnpj: string) {
    const value = cpfCnpj.replace(/\D/g, '');
    let formattedValue = '';

    if (value.length < 11) {
      formattedValue = value;
    } else if (value.length == 11) {
      formattedValue = this.maskService.applyMask(value, this.masks.cpf);
    } else if (value.length > 11) {
      formattedValue = this.maskService.applyMask(value, this.masks.cnpj);
    }

    this.model.get('cpfCnpj')?.setValue(formattedValue);
  }

  onTelefoneInput(event: any): void {
    const formattedTelefone = this.maskService.applyMask(
      event.target.value,
      this.masks.phone
    );
    this.model.get('telefone')?.setValue(formattedTelefone);
  }

  onSecondTelefoneInput(event: any): void {
    const formattedTelefone = this.maskService.applyMask(
      event.target.value,
      this.masks.phone
    );
    this.model.get('telefone2')?.setValue(formattedTelefone);
  }

  onCepInput(event: any): void {
    const formattedCep = this.maskService.applyMask(
      event.target.value,
      this.masks.cep
    );
    this.model.get('cep')?.setValue(formattedCep);
    if (formattedCep.length > 8) {
      this.searchCep(formattedCep);
    }
  }

  changeClientType()
  {
    this.isDependent = this.model.get('dependente')?.value;
  }

  getListClient()
  {
    this.pessoaService.searchList().subscribe({
      next: (response: ListClienteStructResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }

        this.listClients = response.listClienteStruct;
        this.filteredClientes = response.listClienteStruct;
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar lista de clientes',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }

  filterClientes(searchTerm: string): void {
    if (!searchTerm) {
      this.filteredClientes = this.listClients;
    } else {
      this.filteredClientes = this.listClients.filter(clientes =>
        clientes.pessoa.nome.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  }

  onSelectChange(event: any) {
    this.selectedOption = this.listClients.find(cliente => cliente.cliente.idCliente === event.value);
  }
}
