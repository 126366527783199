import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordChangeService } from '../../../shared/services/API/pessoa/password-change.service';
import { ResetPasswordRequest } from '../../../shared/services/requests/pessoa/reset-password.request';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';


@Component({
  selector: 'app-password-change',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule],
  templateUrl: './password-change.component.html',
  styleUrl: './password-change.component.css'
})
export class PasswordChangeComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private passwordChangeService: PasswordChangeService,
    private activatedRoute: ActivatedRoute) {

  }
  public model: FormGroup;
  public isLoading: boolean = false;
  public _token: string;
  public _verifyTokenReturnStruct: ReturnStruct;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      password1: ['', [Validators.required]],
      password2: ['', [Validators.required]]
    });

    this._token = this.activatedRoute.snapshot.paramMap.get('token') + "";
    this.verifyLink();
  }

  redirectLogin() {
    this.router.navigate(['/'])
  }

  post() {
    if (this.model.invalid || this.isLoading)
      return;

    if (this.model.get('password1')?.value != this.model.get('password2')?.value) {
      this.alertService.show("Erro", "As duas senhas precisam ser identicas", AlertType.error);
      return;
    }

    let resetPasswordRequest: ResetPasswordRequest = new ResetPasswordRequest();
    resetPasswordRequest.newPassword = this.model.get('password1')?.value;
    resetPasswordRequest.token = this._token

    this.isLoading = true;

    this.passwordChangeService.put(resetPasswordRequest).subscribe({
      next: (response: ReturnStruct) => {
        this.isLoading = false;

        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show("Sucesso", "Sua senha foi alterada", AlertType.success);
        this.redirectLogin();
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  verifyLink() {
    this.passwordChangeService.get(this._token).subscribe({
      next: (response: ReturnStruct) => {
        this.isLoading = false;

        this._verifyTokenReturnStruct = response;
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }
}
