<div class="main-container">
    <app-subheader [icon]="'person'" [title]="'Cliente'" [module]="'clientes'"
        [description]="_idClient !== null ? 'Edição de um cliente existente' : 'Cadastro de um novo cliente'"
        [isLoading]="isLoading" [isAddPage]="_idClient !== null ? false : true"
        [isEditPage]="_idClient !== null ? true : false"></app-subheader>
    <div class="container-separation">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="grid">
                <div class="section">
                    <div class="title-section">
                        <h2>DADOS PRINCIPAIS</h2>
                    </div>
                    <div class="form-grid">
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>CPF/CNPJ</mat-label>
                                <input matInput id="cpfCnpj" formControlName="cpfCnpj" type="text"
                                    (input)="onCpfCnpjInput($event)" placeholder="000.000.000-00 || 00.000.000/0000-00"
                                    class="field" />
                                <mat-error *ngIf="model.get('cpfCnpj')?.touched && model.get('cpfCnpj')?.invalid">
                                    O campo CPF/CNPJ é obrigatório.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome</mat-label>
                                <input matInput id="nome" formControlName="nome" type="text" class="field" />
                                <mat-error *ngIf="model.get('nome')?.touched && model.get('nome')?.invalid">
                                    O campo nome é obrigatório.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Razão Social</mat-label>
                                <input matInput id="razaoSocial" formControlName="razaoSocial" type="text"
                                    placeholder="Razão Social" class="field" />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="title-section">
                        <h2>DADOS ADICIONAIS</h2>
                    </div>
                    <div class="form-grid">
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Email</mat-label>
                                <input matInput id="email" formControlName="email" type="email"
                                    placeholder="exemplo@dominio.com" class="field" />
                                <mat-error *ngIf="model.get('email')?.touched && model.get('email')?.invalid">
                                    O email informado é inválido.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Telefone</mat-label>
                                <input matInput id="telefone" formControlName="telefone" type="text"
                                    (input)="onTelefoneInput($event)" placeholder="(00) 00000-0000" class="field" />
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Telefone 2</mat-label>
                                <input matInput id="telefone2" formControlName="telefone2" type="text"
                                    (input)="onSecondTelefoneInput($event)" placeholder="(00) 00000-0000"
                                    class="field" />
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Data de Nascimento</mat-label>
                                <input matInput id="nascimento" formControlName="nascimento" type="date"
                                    class="field" />
                            </mat-form-field>
                        </div>
                        <div class="form-group" *ngIf="isViewFinancialFields">
                            <mat-form-field appearance="outline">
                                <mat-label>Limite</mat-label>
                                <input matInput formControlName="limite" type="number" placeholder="Limite"
                                    class="field" (disabled)="!isWriteFinancialFields" />
                            </mat-form-field>
                        </div>
                        <div class="form-group" *ngIf="isViewFinancialFields">
                            <mat-form-field appearance="outline">
                                <mat-label>Renda</mat-label>
                                <input matInput formControlName="renda" type="number" placeholder="renda"
                                    class="field" (disabled)="!isWriteFinancialFields" />
                            </mat-form-field>
                        </div>
                        <div class="form-group check-box">
                            <mat-checkbox formControlName="dependente" class="field" (ngModelChange)="changeClientType()">
                                Sou Dependente
                            </mat-checkbox>
                        </div>
                        <div class="form-group" *ngIf="isDependent">
                            <mat-form-field appearance="outline">
                                <mat-label>Cliente Titular</mat-label>
                                <mat-select formControlName="idClienteTitular" class="field">
                                    <mat-select-trigger>
                                        {{ selectedOption?.pessoa?.nome || 'Selecione um cliente' }}
                                    </mat-select-trigger>
    
                                    <input type="text" [formControl]="searchControl" placeholder="Pesquise um cliente..." class="input" />
    
                                    <mat-option *ngFor="let client of filteredClientes" [value]="client.cliente.idCliente">
                                        {{ client.pessoa.nome }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="title-section">
                        <h2>ENDEREÇO</h2>
                    </div>
                    <div class="form-grid">
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>CEP</mat-label>
                                <input matInput id="cep" formControlName="cep" type="text" (input)="onCepInput($event)"
                                    placeholder="CEP" class="field" />
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Logradouro</mat-label>
                                <input matInput id="logradouro" formControlName="logradouro" type="text"
                                    placeholder="Logradouro" class="field" />
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Cidade</mat-label>
                                <input matInput id="cidade" formControlName="cidade" type="text" placeholder="Cidade"
                                    class="field" />
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>UF</mat-label>
                                <input matInput id="uf" formControlName="uf" type="text" placeholder="UF"
                                    class="field" />
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Número</mat-label>
                                <input matInput id="numero" formControlName="numero" type="text" placeholder="Número"
                                    class="field" #numeroEndereco />
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Bairro</mat-label>
                                <input matInput id="bairro" formControlName="bairro" type="text" placeholder="Bairro"
                                    class="field" />
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Complemento</mat-label>
                                <input matInput id="complemento" formControlName="complemento" type="text"
                                    placeholder="complemento" class="field" />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="button-display">
        <div class="submit">
            <button class="button btn-primary save" (click)="submit()" [disabled]="isLoading">
                <ng-container *ngIf="isLoading; else notLoading">
                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                </ng-container>
                <ng-template #notLoading>
                    Salvar
                </ng-template>
            </button>

            <button class="button cancel" (click)="backPage()">Cancelar</button>
        </div>
    </div>
</div>