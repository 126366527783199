import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmModalComponent } from '../../../../shared/component/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ReturnStruct } from '../../../../shared/services/structs/return.struct';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProdutoResponse } from '../../../../shared/services/responses/estoque/produto.response';
import { EstoqueService } from '../../../../shared/services/API/estoque/estoque.service';
import { ProdutoImagemStruct } from '../../../../shared/services/structs/estoque/produto-imagem.struct';
import { ProdutoImagemRequest } from '../../../../shared/services/requests/estoque/produto-imagem.request';
import { ProdutoImagemRequestStruct } from '../../../../shared/services/structs/estoque/produto-imagem-request.struct';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-section-product-images',
  standalone: true,
  imports: [
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    CommonModule,
  ],
  templateUrl: './section-product-images.component.html',
  styleUrl: './section-product-images.component.css'
})
export class SectionProductImagesComponent {
  @Input() _produtoResponse: ProdutoResponse;
  @Output() reloadProduct = new EventEmitter<any>()
  public isLoading: boolean = false;

  constructor(public dialog: MatDialog,
    public estoqueService: EstoqueService,
    private alertService: AlertService
  ) {
  }

  deleteImg(productImageStruct: ProdutoImagemStruct) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: "Atenção",
        description: "Deseja realmente excluir essa imagem? Essa ação será realizada agora e será irreversível.",
        buttonConfirm: "Confirmar",
        buttonClose: "Cancelar"
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm) {
          this.estoqueService.DeleteProdutoImage(productImageStruct.idProdutoImagem).subscribe({
            next: (returnStruct: ReturnStruct) => {
              if (returnStruct.isError) {
                this.alertService.show("Erro inesperado", returnStruct.errorDescription, AlertType.error);
                return;
              }

              this.reloadProduct.emit();

            },
            error: (error) => {
              this.alertService.show("Erro inesperado", error, AlertType.error);
            }
          });
        }
      }
    })
  }

  onFileSelected(event: any) {
    if (!event.target.files || this.isLoading)
      return;

    this.isLoading = true;

    let newProductImageRequest: ProdutoImagemRequest = new ProdutoImagemRequest();
    newProductImageRequest.listProdutoImagemRequestStruct = [];
    newProductImageRequest.idProduto = this._produtoResponse.produtoStruct.produto.idProduto;

    for (let index = 0; index < event.target.files.length; index++) {

      const file: File = event.target.files[index];
      let fileExtension = file.name.split(".")?.pop()?.toLowerCase();

      if (fileExtension != "jpg"
        && fileExtension != "png"
        && fileExtension != "jpeg"
        && fileExtension != "gif"
        && fileExtension != "bmp"
        && fileExtension != "tif") {
        this.alertService.show("Erro no upload", "Um ou mais arquivos possuem uma extenção não valida.", AlertType.error);
        this.isLoading = false;
        return;
      }

      let productImageRequestStruct: ProdutoImagemRequestStruct = new ProdutoImagemRequestStruct();

      const reader = new FileReader();
      reader.onload = () => {
        productImageRequestStruct.base64 = (reader.result as string).split("base64,", 2)[1];
        newProductImageRequest.listProdutoImagemRequestStruct.push(productImageRequestStruct);
      }
      reader.readAsDataURL(file);
    }
    setTimeout(() => {
      this.estoqueService.CreateProdutoImage(newProductImageRequest).subscribe({
        next: (returnStruct: ReturnStruct) => {
          this.isLoading = false;
          if (returnStruct.isError) {
            this.alertService.show("Erro inesperado", returnStruct.errorDescription, AlertType.error);
            return;
          }

          this.reloadProduct.emit();
        },
        error: (error) => {
          this.isLoading = false;
          this.alertService.show("Erro inesperado", error, AlertType.error);
        }
      });
    }, 1000);
  }

  openModalAddImg() {

  }
}
