import { Component, OnInit } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Subject, debounceTime } from 'rxjs';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import {
  AlertService,
  AlertType,
} from '../../../shared/services/alert.service';
import { PessoaService } from '../../../shared/services/API/pessoa/pessoa.service';
import { AuthService } from '../../../shared/services/auth.service';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { Router } from '@angular/router';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';
import { TipoListaConfigEnum } from '../../../shared/enum/Config/tipo-lista-config.enum';
import { LoadingBoxComponent } from '../../../shared/component/loading-box/loading-box.component';
import { TipoAddConfigEnum } from '../../../shared/enum/Config/tipo-add-config.enum';
import { ListStatusStruct } from '../../../shared/services/structs/listStatus.struct';
import { ListUsuarioResponse } from '../../../shared/services/responses/pessoa/list-usuario.response';
import { ListEmpresaResponse } from '../../../shared/services/responses/pessoa/list-empresa.response';
import { UsuarioSearchRequest } from '../../../shared/services/requests/pessoa/usuario-search.request';
import { StatusEnum } from '../../../shared/enum/status.enum';
import { OpenTextFilterComponent } from '../../../shared/component/filter-list/open-text-filter/open-text-filter.component';
import { DateFilterComponent } from '../../../shared/component/filter-list/date-filter/date-filter.component';
import { EmpresaSearchRequest } from '../../../shared/services/requests/pessoa/empresa-search.request';
import { BancoEmpresaSearchRequest } from '../../../shared/services/requests/financeiro/banco-empresa-search.request';
import { FinanceiroService } from '../../../shared/services/API/financeiro/financeiro.service';
import { ListBancoEmpresaStructResponse } from '../../../shared/services/responses/financeiro/list-banco-empresa-struct.response';
import { Location } from '@angular/common';
import { SubHeaderComponent } from '../../../shared/component/sub-header/sub-header.component';
import { RoutesService } from '../../../routes.service';

@Component({
  selector: 'app-list-config',
  standalone: true,
  imports: [
    MatIconModule,
    MatProgressSpinnerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingBoxComponent,
    OpenTextFilterComponent,
    DateFilterComponent,
    SubHeaderComponent,
  ],
  templateUrl: './list-config.component.html',
  styleUrl: './list-config.component.css',
})
export class ListConfigComponent implements OnInit {
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  searchSubject: Subject<string> = new Subject();
  searchDate: Subject<string> = new Subject();
  public color: string = DefaultColorEnum.Cor;
  buttonStyle: any = {
    backgroundColor: this.color,
    color: 'white',
  };
  secondButtonStyle: any = {
    backgroundColor: 'transparent',
    color: this.color,
  };
  thirdButtonStyle: any = {
    backgroundColor: 'transparent',
    color: this.color,
  };
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public pessoaService: PessoaService,
    public financeiroService: FinanceiroService,
    private formBuilder: FormBuilder,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private location: Location,
    private routesService: RoutesService
  ) {
    this.searchSubject.pipe(debounceTime(2000)).subscribe((value) => {
      this.model.get('search')?.setValue(value);
      this.searchCard();
    });
    this.searchDate.pipe(debounceTime(2000)).subscribe((value) => {
      this.model.get('filterDate')?.setValue(value);
      this.searchCard();
    });
  }
  public model: FormGroup;
  public nameAdd: string = TipoAddConfigEnum.Usuarios;
  public tipoUsuarios: number = TipoListaConfigEnum.Usuarios;
  public tipoBancos: number = TipoListaConfigEnum.Bancos;
  public tipoEmpresa: number = TipoListaConfigEnum.Empresa;
  public tipoCard: number = TipoListaConfigEnum.Usuarios;
  public dataList: any[];
  public searchTimeout: any;
  public nameProfile: string = 'Configurações';
  public iconProfile: string = 'settings';
  public profile: number = DefaultProfilesEnum.Configuração;
  public status: ListStatusStruct = new ListStatusStruct();
  public listStatus = this.status.listStatus;
  public ativo: boolean = true;
  public redirectUrl: string = this.routesService.ADD_USER;
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      search: [''],
      filterDate: [''],
      currentPage: [0],
      totalPages: [0],
      statusSelect: [1],
    });

    this.userLoginResponse = this.authService.getUserInfos();
    this.authService.permissao(this.profile, PapelEnum.ler);

    if (this.userLoginResponse.empresaLogada?.corSistema) {
      this.color = this.userLoginResponse.empresaLogada?.corSistema;
      this.changeColor();
    }
    this.nameProfile =
      this.userLoginResponse.listPerfil.find((x) => x.idPerfil == this.profile)
        ?.nomePerfil ?? this.nameProfile;
    this.iconProfile =
      this.userLoginResponse.listPerfil.find((x) => x.idPerfil == this.profile)
        ?.googleIcone ?? this.iconProfile;
    this.searchCard();
  }

  changeColor() {
    document.documentElement.style.setProperty('--main-color', this.color);
  }

  onSearchValueChanged(value: string) {
    this.searchSubject.next(value);
  }

  onDateValueChanged(value: any) {
    this.searchDate.next(value.date);
  }

  goToPreviousPage() {
    const currentPage = this.model.get('currentPage')?.value;
    if (currentPage > 1) {
      this.model.get('currentPage')?.setValue(currentPage - 1);
      this.searchCard();
    }
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  goToNextPage() {
    const currentPage = this.model.get('currentPage')?.value;
    const totalPages = this.model.get('totalPages')?.value;
    if (currentPage < totalPages) {
      this.model.get('currentPage')?.setValue(currentPage + 1);
      this.searchCard();
    }
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  searchCard() {
    this.isLoading = true;

    if (this.tipoCard == this.tipoUsuarios) {
      const searchRequest: UsuarioSearchRequest = new UsuarioSearchRequest();
      if (this.model.get('search')?.value) {
        searchRequest.searchText = this.model.get('search')?.value;
        this.model.get('currentPage')?.setValue(0);
      }

      if (this.model.get('currentPage')?.value)
        searchRequest.page = this.model.get('currentPage')?.value;

      if (this.model.get('filterDate')?.value) {
        const [startDateString, endDateString] = this.model
          .get('filterDate')
          ?.value.split('|');
        searchRequest.dataInicio = startDateString;
        searchRequest.dataFim = endDateString;
        this.model.get('currentPage')?.setValue(0);
      }
      if (
        this.model.get('statusSelect')?.value &&
        this.model.get('statusSelect')?.value != this.listStatus[0].id
      ) {
        searchRequest.ativo = this.ativo;
        this.model.get('currentPage')?.setValue(0);
      }

      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      this.searchTimeout = setTimeout(() => {
        this.pessoaService.searchUser(searchRequest).subscribe({
          next: (response: ListUsuarioResponse) => {
            if (response.isError) {
              this.alertService.show(
                'Erro inesperado',
                response.errorDescription,
                AlertType.error
              );
              this.isLoading = false;
              return;
            }
            this.dataList = response.listUsuario;
            this.model.get('totalPages')?.setValue(response.pageSize);
            this.isLoading = false;
          },
          error: (error) => {
            this.alertService.show('Erro inesperado', error, AlertType.error);
            this.isLoading = false;
            return;
          },
        });
      }, 3000);
    } else if (this.tipoCard == this.tipoEmpresa) {
      const searchRequest: EmpresaSearchRequest = new EmpresaSearchRequest();
      if (this.model.get('search')?.value) {
        searchRequest.searchText = this.model.get('search')?.value;
        this.model.get('currentPage')?.setValue(0);
      }

      if (this.model.get('currentPage')?.value)
        searchRequest.page = this.model.get('currentPage')?.value;

      if (this.model.get('filterDate')?.value) {
        const [startDateString, endDateString] = this.model
          .get('filterDate')
          ?.value.split('|');
        searchRequest.dataInicio = startDateString;
        searchRequest.dataFim = endDateString;
        this.model.get('currentPage')?.setValue(0);
      }
      if (
        this.model.get('statusSelect')?.value &&
        this.model.get('statusSelect')?.value != this.listStatus[0]
      ) {
        searchRequest.ativo = this.ativo;
        this.model.get('currentPage')?.setValue(0);
      }

      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      this.searchTimeout = setTimeout(() => {
        this.pessoaService.searchEnterprise(searchRequest).subscribe({
          next: (response: ListEmpresaResponse) => {
            if (response.isError) {
              this.alertService.show(
                'Erro inesperado',
                response.errorDescription,
                AlertType.error
              );
              this.isLoading = false;
              return;
            }
            this.dataList = response.listEmpresaStruct;
            this.model.get('totalPages')?.setValue(response.pageSize);
            this.isLoading = false;
          },
          error: (error) => {
            this.alertService.show('Erro inesperado', error, AlertType.error);
            this.isLoading = false;
            return;
          },
        });
      }, 3000);
    } else {
      const searchRequest: BancoEmpresaSearchRequest =
        new BancoEmpresaSearchRequest();
      if (this.model.get('search')?.value) {
        searchRequest.searchText = this.model.get('search')?.value;
        this.model.get('currentPage')?.setValue(0);
      }

      if (this.model.get('currentPage')?.value)
        searchRequest.page = this.model.get('currentPage')?.value;

      if (this.model.get('filterDate')?.value) {
        const [startDateString, endDateString] = this.model
          .get('filterDate')
          ?.value.split('|');
        searchRequest.dataInicio = startDateString;
        searchRequest.dataFim = endDateString;
        this.model.get('currentPage')?.setValue(0);
      }
      if (
        this.model.get('statusSelect')?.value &&
        this.model.get('statusSelect')?.value != this.listStatus[0]
      ) {
        searchRequest.ativo = this.ativo;
        this.model.get('currentPage')?.setValue(0);
      }

      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      this.searchTimeout = setTimeout(() => {
        this.financeiroService.searchBankEnterprise(searchRequest).subscribe({
          next: (response: ListBancoEmpresaStructResponse) => {
            if (response.isError) {
              this.alertService.show(
                'Erro inesperado',
                response.errorDescription,
                AlertType.error
              );
              this.isLoading = false;
              return;
            }
            this.dataList = response.listBancoEmpresaStruct;
            this.model.get('totalPages')?.setValue(response.pageSize);
            this.isLoading = false;
          },
          error: (error) => {
            this.alertService.show('Erro inesperado', error, AlertType.error);
            this.isLoading = false;
            return;
          },
        });
      }, 3000);
    }
  }

  redirect(idRedirect: number) {
    if (this.tipoCard == this.tipoEmpresa) {
      this.router.navigate([
        this.routesService.ENTERPRISE_UPDATE(String(idRedirect)),
      ]);
    } else if (this.tipoCard == this.tipoUsuarios) {
      this.router.navigate([
        this.routesService.USER_UPDATE(String(idRedirect)),
      ]);
    } else {
      this.router.navigate([
        this.routesService.BANK_UPDATE(String(idRedirect)),
      ]);
    }
  }

  changeCard(tipo: number) {
    if (this.tipoCard === tipo) return;
    else {
      if (tipo === this.tipoUsuarios) {
        this.tipoCard = tipo;
        this.buttonStyle.backgroundColor = this.color;
        this.buttonStyle.color = 'white';
        this.secondButtonStyle.backgroundColor = 'white';
        this.secondButtonStyle.color = this.color;
        this.thirdButtonStyle.backgroundColor = 'white';
        this.thirdButtonStyle.color = this.color;
        this.nameAdd = TipoAddConfigEnum.Usuarios;
        this.redirectUrl = this.routesService.ADD_USER;
        this.model.get('search')?.setValue('');
        this.model.get('filterDate')?.setValue(null);
        this.model.get('statusSelect')?.setValue(1);
        this.model.get('currentPage')?.setValue(0);
        this.searchCard();
      } else if (tipo === this.tipoBancos) {
        this.tipoCard = tipo;
        this.buttonStyle.backgroundColor = 'white';
        this.buttonStyle.color = this.color;
        this.thirdButtonStyle.backgroundColor = 'white';
        this.thirdButtonStyle.color = this.color;
        this.secondButtonStyle.backgroundColor = this.color;
        this.secondButtonStyle.color = 'white';
        this.nameAdd = TipoAddConfigEnum.Bancos;
        this.redirectUrl = this.routesService.ADD_BANK;
        this.model.get('search')?.setValue('');
        this.model.get('filterDate')?.setValue(null);
        this.model.get('statusSelect')?.setValue(1);
        this.model.get('currentPage')?.setValue(0);
        this.searchCard();
      } else {
        this.tipoCard = tipo;
        this.thirdButtonStyle.backgroundColor = this.color;
        this.thirdButtonStyle.color = 'white';
        this.buttonStyle.backgroundColor = 'white';
        this.buttonStyle.color = this.color;
        this.secondButtonStyle.backgroundColor = 'white';
        this.secondButtonStyle.color = this.color;
        this.nameAdd = TipoAddConfigEnum.Empresa;
        this.redirectUrl = this.routesService.ADD_ENTERPRISE;
        this.model.get('search')?.setValue('');
        this.model.get('filterDate')?.setValue(null);
        this.model.get('statusSelect')?.setValue(1);
        this.model.get('currentPage')?.setValue(0);
        this.searchCard();
      }
    }
  }

  onStatusChange(event: any) {
    if (event.target.value == StatusEnum.Ativo) {
      this.ativo = true;
      this.model.get('currentPage')?.setValue(0);
      this.searchCard();
    } else {
      this.ativo = false;
      this.model.get('currentPage')?.setValue(0);
      this.searchCard();
    }
  }

  getVisibleTags(tags: string[]): {
    visibleTags: string[];
    remainingCount: number;
  } {
    const maxVisibleTags = 3;
    const visibleTags = tags.slice(0, maxVisibleTags);
    const remainingCount = tags.length - visibleTags.length;
    return { visibleTags, remainingCount };
  }
}
