<div class="subheader">
  <div class="breadcrumb">
    <mat-icon (click)="navigateTo('/home')" class="icon-home">home</mat-icon>
    <span class="breadcrumb-separator">›</span>
    <span (click)="navigateTo('/' + title.toLowerCase())" class="breadcrumb-item">{{ title }}</span>
    @if (isAddPage) {
    <span class="breadcrumb-separator">›</span>
    <span class="breadcrumb-item">Novo {{ title.toLowerCase() }}</span>
    }
    @if (isEditPage) {
    <span class="breadcrumb-separator">›</span>
    <span class="breadcrumb-item">Editando {{ title.toLowerCase() }}</span>
    }
  </div>
  <div class="wrapper">
    <div class="list-item">
      <mat-icon class="icon-item">{{ icon }}</mat-icon>
      <div class="list-content">
        <h1 class="title">{{ title }}</h1>
        <p class="desc">{{ description }}</p>
      </div>
    </div>
    @if (!isAddPage && !isEditPage) {
    <button (click)="navigateTo('/' + title.toLowerCase() + '/' + module.toLowerCase() + '/novo')" class="add-new-btn">ADICIONAR NOVO</button>
    }
  </div>
</div>