import { NovaSubgradeStruct } from "./nova-subgrade-struct";

export class ProdutoGradeStruct {
    public idGrade: number | null;

    public nomeGrade: string;

    public listNovaSubgradeStruct: NovaSubgradeStruct[] = [];

    public listProdutoSubgradeStruct: NovaSubgradeStruct[] = [];
}