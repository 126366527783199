import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DefaultColorEnum } from '../../enum/default-color.enum';
import { AuthService } from '../../services/auth.service';
import { UserLoginResponse } from '../../services/responses/auth/user-login.response';
import { Location } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';


@Component({
  selector: 'app-sub-header',
  standalone: true,
  imports: [
    MatIconModule,
    MatProgressSpinnerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './sub-header.component.html',
  styleUrl: './sub-header.component.css'
})
export class SubHeaderComponent implements OnInit {
  @Input() showButtonAdd: boolean = false;
  @Input() redirectUrl: string;
  @Input() nameProfile: string;
  @Input() iconProfile: string;
  @Input() nameAdd: string;
  @Input() isLoading: boolean = true;
  public userLoginResponse: UserLoginResponse;
  public color: string = DefaultColorEnum.Cor;
  constructor(
    private authService: AuthService,
    private router: Router,
    private location: Location
  ) { }
  ngOnInit(): void {
    this.userLoginResponse = this.authService.getUserInfos();

    if (this.userLoginResponse.empresaLogada?.corSistema) {
      this.color = this.userLoginResponse.empresaLogada?.corSistema;
      this.changeColor();
    }
  }

  changeColor() {
    document.documentElement.style.setProperty('--main-color', this.color);
  }

  returnPage() {
    this.location.back();
  }
  redirect() {
    this.router.navigate([this.redirectUrl]);
  }
}