<app-action-notification [showActionNotification]="showActionNotification" (showActionNotificationFalse)="clickShowActionNotification(false)" (setAreThereNotifications)="setAreThereNotifications($event)"></app-action-notification>
<app-action-user [showActionUser]="showActionUser" (showActionUserFalse)="clickShowActionUser(false)"></app-action-user>
<header>
    <div class="header-menu">
        <div class="menu">
            <!-- <div class="action-menu">
                <a (click)="toggleNavbar()">
                    <span class="material-symbols-outlined large-icon">apps</span>
                </a>
            </div> -->
            <a (click)="clickRedirectHome()">
                <img [src]="empresaLogo" alt="Logo da Empresa" class="logo-img" />
            </a>
        </div>
        <!-- <div class="search-bar">
            <app-search-bar class="search" *ngIf="showSearch"></app-search-bar>
        </div> -->
        <div class="action">
            <a (click)="toggleTheme()" mat-raised-button matTooltip="Trocar tema" aria-label="Trocar tema"><span class="material-symbols-outlined large-icon">{{ currentTheme === 'light' ? 'dark_mode' : 'light_mode' }}</span></a>
            @if (areThereNotifications) {
            <a (click)="clickShowActionNotification(null)" mat-raised-button matTooltip="Notificações" aria-label="Notificações"><span matBadge="!" matBadgeColor="accent" matBadgeSize="small" matBadgeOverlap="false" class="material-symbols-outlined large-icon">notifications</span></a>
            }
            @else {
            <a (click)="clickShowActionNotification(null)" mat-raised-button matTooltip="Sem notificações" aria-label="Sem notificações"><span class="material-symbols-outlined large-icon">notifications</span></a>
            }
            <!-- <a (click)="clickRerirectConfig()" mat-raised-button matTooltip="Configurações"
                aria-label="Configurações"><span class="material-symbols-outlined large-icon">settings</span></a> -->
            <a (click)="clickShowActionUser(null)" mat-raised-button matTooltip="Minha conta" aria-label="Minha conta"><span class="material-symbols-outlined large-icon">account_circle</span></a>
        </div>
    </div>
    <!-- <app-search-bar class="search-mini" *ngIf="showSearch"></app-search-bar> -->
</header>

<app-action-nav></app-action-nav>

<div [ngClass]="{'main-wrapper-expanded': showActionNav, 'main-wrapper': !showActionNav}">
    <app-search-bar [isCompact]="true" *ngIf="showSearch"></app-search-bar>
    <ng-content></ng-content>
</div>
<!-- <div class="space-header"></div> -->