<div class="main-container">
    <app-subheader [icon]="'shopping_cart_checkout'" [title]="'Vendas'" [module]="'vendas'" [description]="'Suas vendas'" [isLoading]="isLoading" [isAddPage]="false" [isEditPage]="false"></app-subheader>
    <div class="container-separation">
    <div class="pdv-container">
        <!-- Área da Tabela de Produtos -->
        <div class="pdv-left col-md-7">
        <div class="search-section">
            <input
            type="text"
            placeholder="Pesquise por produtos ou use o leitor de código de barras"
            class="search-bar"
            [(ngModel)]="searchTerm"
            (keyup)="searchProduct()"
            />
        </div>
        <div class="table-container">
            <table class="styled-table">
            <thead>
                <tr>
                <th>Item</th>
                <!-- <th>ID</th> -->
                <th>Produto</th>
                <th>Qtd</th>
                <th>Valor</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let product of products">
                <td>{{ product.item }}</td>
                <!-- <td>{{ product.id }}</td> -->
                <td>{{ product.name }}</td>
                <td class="quantity">
                    <button (click)="decreaseQuantity(product)" class="btn-red">-</button>
                    <span>{{ product.quantity }}</span>
                    <button (click)="increaseQuantity(product)" class="btn-green">+</button>
                </td>
                <td>{{ product.price | currency:'BRL' }}</td>
                </tr>
            </tbody>
            </table>
        </div>
        </div>
        <div class="pdv-left col-md-1">
        </div>
        <!-- Área de Pagamento -->
        <div class="pdv-right col-md-4">
            <div class="search-section">
                <input
                type="text"
                placeholder="Pesquise ou adicione um cliente"
                class="search-bar"
                [(ngModel)]="searchTerm"
                (keyup)="searchProduct()"
                />
            </div>
            <div class="total-display">
                <h2>Total: {{ calculateTotal() | currency:'BRL' }}</h2>
            </div>
            <div class="payment-section">
                <label for="paymentMethod">Forma de Pagamento</label>
                <select id="paymentMethod" [(ngModel)]="paymentMethod">
                <option value="cash">Dinheiro</option>
                <option value="card">Cartão</option>
                </select>
                <input
                type="number"
                placeholder="Valor recebido"
                [(ngModel)]="receivedAmount"
                class="received-input"
                />
                <h3>Troco: {{ calculateChange() | currency:'BRL' }}</h3>
            </div>
            <button (click)="finalizeSale()" class="finalize-button">
                Finalizar R$ {{ calculateTotal() | currency:'BRL' }}
            </button>
            </div>
    </div>
</div>