<div *ngIf="!isLoading" class="exhibit-container">
    <div *ngIf="isShowLoading; else content" class="loading-placeholder">
        <table>
            <tr *ngFor="let row of emptyRows">
                <td *ngFor="let _ of emptyColumns" class="loading-cell">
                    <mat-progress-spinner mode="indeterminate" diameter="20" class="spinner"></mat-progress-spinner>
                </td>
            </tr>
        </table>
    </div>
    <ng-template #content>
        <div class="filter-container">
            <app-open-text-filter (valueChanged)="onFilterChange($event)"></app-open-text-filter>
            <div class="export-icon">
                <mat-icon class="export-icon" *ngIf="isTable" (click)="exportToPDF()">picture_as_pdf</mat-icon>
            </div>
            <app-date-filter (valueChanged)="onDateSearchChange($event)"></app-date-filter>
        </div>
        <div id="contextMenu" class="context-menu" [style.left.px]="contextMenuPosition.x" [style.top.px]="contextMenuPosition.y">
            <ul>
                <li (click)="removeNulls()">Remover vazios</li>
                <li (click)="filterByValue()">Filtrar por Valor</li>
                <li (click)="filterByRange()">Filtrar por Intervalo</li>
                <li (click)="removeFilter()">Remover filtros avançados</li>
            </ul>
        </div>


        <div class="person-table" *ngIf="isTable && isPerson">
            <table id="tabela">
                <thead>
                    <tr>
                        <th (click)="sortData('cpfCnpj')" style="cursor: pointer;" (contextmenu)="onRightClick($event)" (contextmenu)="setSelectedColumn('cpfCnpj')">
                            CPF/CNPJ
                            <span *ngIf="sortedColumn === 'cpfCnpj'">
                                {{ sortOrder === 'asc' ? '⬆️' : '⬇️' }}
                            </span>
                        </th>

                        <th (click)="sortData('nome')" style="cursor: pointer;" (contextmenu)="onRightClick($event)" (contextmenu)="setSelectedColumn('nome')">
                            NOME
                            <span *ngIf="sortedColumn === 'nome'">
                                {{ sortOrder === 'asc' ? '⬆️' : '⬇️' }}
                            </span>
                        </th>

                        <th (click)="sortData('email')" style="cursor: pointer;" (contextmenu)="onRightClick($event)" (contextmenu)="setSelectedColumn('email')">
                            EMAIL
                            <span *ngIf="sortedColumn === 'email'">
                                {{ sortOrder === 'asc' ? '⬆️' : '⬇️' }}
                            </span>
                        </th>

                        <th (click)="sortData('status')" style="cursor: pointer;" (contextmenu)="onRightClick($event)" (contextmenu)="setSelectedColumn('status')">
                            STATUS
                            <span *ngIf="sortedColumn === 'status'">
                                {{ sortOrder === 'asc' ? '⬆️' : '⬇️' }}
                            </span>
                        </th>

                        <th (click)="sortData('telefone')" style="cursor: pointer;" (contextmenu)="onRightClick($event)" (contextmenu)="setSelectedColumn('telefone')">
                            TELEFONE
                            <span *ngIf="sortedColumn === 'telefone'">
                                {{ sortOrder === 'asc' ? '⬆️' : '⬇️' }}
                            </span>
                        </th>

                        <th (click)="sortData('inclusao')" style="cursor: pointer;" (contextmenu)="onRightClick($event)" (contextmenu)="setSelectedColumn('inclusao')">
                            INCLUSÃO
                            <span *ngIf="sortedColumn === 'inclusao'">
                                {{ sortOrder === 'asc' ? '⬆️' : '⬇️' }}
                            </span>
                        </th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of filteredData">
                        <td>
                            {{row.cpfCnpj}}
                        </td>

                        <td>
                            {{row.nome}}
                        </td>

                        <td>
                            {{row.email}}
                        </td>

                        <td>
                            {{row.status}}
                        </td>

                        <td>
                            {{row.telefone}}
                        </td>

                        <td>
                            {{row.inclusao}}
                        </td>

                        <td>
                            <mat-icon class="button" (click)="edit(row)">edit</mat-icon>
                            <mat-icon class="button" (click)="remove(row)">delete</mat-icon>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="person-table" *ngIf="isTable && !isPerson && isBill">
            <table id="tabela">
                <thead>
                    <tr>
                        <th (click)="sortData('fornecedor')" style="cursor: pointer;" (contextmenu)="onRightClick($event)" (contextmenu)="setSelectedColumn('fornecedor')">
                            FORNECEDOR
                            <span *ngIf="sortedColumn === 'fornecedor'">
                                {{ sortOrder === 'asc' ? '⬆️' : '⬇️' }}
                            </span>
                        </th>

                        <th (click)="sortData('subCategoria')" style="cursor: pointer;" (contextmenu)="onRightClick($event)" (contextmenu)="setSelectedColumn('subCategoria')">
                            CATEGORIA
                            <span *ngIf="sortedColumn === 'subCategoria'">
                                {{ sortOrder === 'asc' ? '⬆️' : '⬇️' }}
                            </span>
                        </th>

                        <th (click)="sortData('valorConta')" style="cursor: pointer;" (contextmenu)="onRightClick($event)" (contextmenu)="setSelectedColumn('valorConta')">
                            VALOR DA CONTA
                            <span *ngIf="sortedColumn === 'valorConta'">
                                {{ sortOrder === 'asc' ? '⬆️' : '⬇️' }}
                            </span>
                        </th>

                        <th (click)="sortData('vencimento')" style="cursor: pointer;" (contextmenu)="onRightClick($event)" (contextmenu)="setSelectedColumn('vencimento')">
                            VENCIMENTO
                            <span *ngIf="sortedColumn === 'vencimento'">
                                {{ sortOrder === 'asc' ? '⬆️' : '⬇️' }}
                            </span>
                        </th>

                        <th (click)="sortData('status')" style="cursor: pointer;" (contextmenu)="onRightClick($event)" (contextmenu)="setSelectedColumn('status')">
                            STATUS
                            <span *ngIf="sortedColumn === 'status'">
                                {{ sortOrder === 'asc' ? '⬆️' : '⬇️' }}
                            </span>
                        </th>
                        <th (click)="sortData('dataInclusao')" style="cursor: pointer;" (contextmenu)="onRightClick($event)" (contextmenu)="setSelectedColumn('dataInclusao')">
                            INCLUSÃO
                            <span *ngIf="sortedColumn === 'dataInclusao'">
                                {{ sortOrder === 'asc' ? '⬆️' : '⬇️' }}
                            </span>
                        </th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of filteredData">
                        <td>
                            {{row.fornecedor}}
                        </td>

                        <td>
                            {{row.subCategoria}}
                        </td>

                        <td>
                            {{row.valorConta}}
                        </td>

                        <td>
                            {{row.vencimento}}
                        </td>

                        <td>
                            {{row.status}}
                        </td>

                        <td>
                            {{row.dataInclusao}}
                        </td>

                        <td>
                            <mat-icon class="button" (click)="edit(row)">edit</mat-icon>
                            <mat-icon class="button" (click)="remove(row)">delete</mat-icon>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="product-table" *ngIf="isTable && !isPerson && !isBill">
            <table id="tabela">
                <thead>
                    <tr>

                        <th (click)="sortData('nome')" style="cursor: pointer;" (contextmenu)="onRightClick($event)" (contextmenu)="setSelectedColumn('nome')">
                            NOME
                            <span *ngIf="sortedColumn === 'nome'">
                                {{ sortOrder === 'asc' ? '⬆️' : '⬇️' }}
                            </span>
                        </th>

                        <th (click)="sortData('referencia')" style="cursor: pointer;" (contextmenu)="onRightClick($event)" (contextmenu)="setSelectedColumn('referencia')">
                            REF
                            <span *ngIf="sortedColumn === 'referencia'">
                                {{ sortOrder === 'asc' ? '⬆️' : '⬇️' }}
                            </span>
                        </th>

                        <th (click)="sortData('ean')" style="cursor: pointer;" (contextmenu)="onRightClick($event)" (contextmenu)="setSelectedColumn('ean')">
                            EAN
                            <span *ngIf="sortedColumn === 'ean'">
                                {{ sortOrder === 'asc' ? '⬆️' : '⬇️' }}
                            </span>
                        </th>

                        <th (click)="sortData('nomeGrupo')" style="cursor: pointer;" (contextmenu)="onRightClick($event)" (contextmenu)="setSelectedColumn('nomeGrupo')">
                            GRUPO
                            <span *ngIf="sortedColumn === 'nomeGrupo'">
                                {{ sortOrder === 'asc' ? '⬆️' : '⬇️' }}
                            </span>
                        </th>

                        <th (click)="sortData('quantEstoque')" style="cursor: pointer;" (contextmenu)="onRightClick($event)" (contextmenu)="setSelectedColumn('quantEstoque')">
                            ESTOQUE
                            <span *ngIf="sortedColumn === 'quantEstoque'">
                                {{ sortOrder === 'asc' ? '⬆️' : '⬇️' }}
                            </span>
                        </th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of filteredData">
                        <td>
                            {{row.nome}}
                        </td>

                        <td>
                            {{row.referencia}}
                        </td>

                        <td>
                            {{row.ean}}
                        </td>

                        <td>
                            {{row.nomeGrupo}}
                        </td>

                        <td>
                            {{row.quantEstoque}}
                        </td>

                        <td>
                            <mat-icon class="button" (click)="edit(row)">edit</mat-icon>
                            <mat-icon class="button" (click)="remove(row)">delete</mat-icon>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="person-card" *ngIf="!isTable && isPerson">
            <div class="separation" *ngFor="let item of data">
                <div class="search-item" (click)="edit(item)">
                    @if(item != null)
                    {
                    <div class="item-images">
                        <mat-icon class="card-icon">person</mat-icon>
                    </div>
                    <div class="item-details">
                        <div class="item-header">
                            <span class="item-name">{{item.nome}}</span>
                        </div>

                        <div class="item-data">
                            <span><strong>CPF/CNPJ: </strong> {{item.cpfCnpj}}</span>
                            <div>
                                <span><strong>EMAIL: </strong> {{item.email}}</span>
                            </div>
                            <div>
                                <span><strong>TELEFONE: </strong> {{item.telefone}}</span>
                            </div>
                            <div>
                                <span><strong>STATUS: </strong> <span class="item-stock">{{item.status}}</span></span>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                <div class="action">
                    <mat-icon class="button" (click)="remove(item)">delete</mat-icon>
                </div>
            </div>
        </div>

        <div class="person-card" *ngIf="!isTable && !isPerson && isBill">
            <div class="separation" *ngFor="let item of data">
                <div class="search-item" (click)="edit(item)">
                    @if(item != null)
                    {
                    <div class="item-images">
                        <mat-icon class="card-icon">currency_exchange</mat-icon>
                    </div>
                    <div class="item-details">
                        <div class="item-header">
                            <span class="item-id">#{{item.idContasPagar}}</span>
                        </div>

                        <div class="item-data">
                            <span><strong>FORNECEDOR: </strong> {{item.fornecedor}}</span>
                            <div>
                                <span><strong>CATEGORIA: </strong> {{item.subCategoria}}</span>
                            </div>
                            <div>
                                <span><strong>VALOR DA CONTA: </strong> {{item.valorConta}}</span>
                            </div>
                            <div>
                                <span><strong>VENCIMENTO: </strong> <span class="item-stock">{{item.vencimento}}</span></span>
                            </div>
                            <div>
                                <span><strong>STATUS: </strong> <span class="item-stock">{{item.status}}</span></span>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                <div class="action">
                    <mat-icon class="button" (click)="remove(item)">delete</mat-icon>
                </div>
            </div>
        </div>

        <div class="product-card" *ngIf="!isTable && !isPerson && !isBill">
            <div class="separation" *ngFor="let item of data">
                <div class="search-item" (click)="edit(item)">
                    @if(item != null)
                    {
                    <div class="item-images">
                        @if(item.imagemUrl != null && item.imagemUrl.length > 0) {
                        <img src="{{item.imagemUrl}}" alt="Imagem do Produto" />
                        } @else {
                        <img src="assets/images/padrao-imagem-produto.png" alt="Imagem do Produto" />
                        }
                    </div>
                    <div class="item-details">
                        <div class="item-header">
                            <span class="item-id">#{{item.idProduto}} </span>
                            <span class="item-name">{{item.nome}} - {{item.nomeMarca}}</span>
                        </div>

                        <div class="item-data">
                            <span><strong>Ref.</strong> {{item.referencia}}</span>
                            <div>
                                <span><strong>EAN</strong> {{item.ean}}</span>
                            </div>
                            <div>
                                <span><strong>Grupo:</strong> {{item.nomeGrupo}} |
                                    {{item.nomeSubgrupo}}</span>
                            </div>
                            <div>
                                <span><strong>Estoque:</strong> <span class="item-stock">{{item.quantEstoque}}
                                        Unidades</span></span>
                            </div>
                        </div>

                        <div class="item-price-container">
                            <label class="item-price">{{item.preco}}</label>
                        </div>
                    </div>
                    }
                </div>
                <div class="action">
                    <mat-icon class="button" (click)="remove(item)">delete</mat-icon>
                </div>
            </div>
        </div>
    </ng-template>
</div>