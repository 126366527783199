export const environment = {
  production: false,
  staging: false,
  urlApiBase: 'https://dev.atsone.com.br/',
  urlApiAuth: 'https://api.dev.atsone.com.br/one-api-auth/',
  urlApiPessoa: 'https://api.dev.atsone.com.br/one-api-pessoa/',
  urlApiFinanceiro: 'https://api.dev.atsone.com.br/one-api-financeiro/',
  urlApiEstoque: 'https://api.dev.atsone.com.br/one-api-estoque/',
  urlApiSpeech: 'https://api.dev.atsone.com.br/one-api-speech/',
  urlApiFiscal: 'https://api.dev.atsone.com.br/one-api-fiscal/',
  urlApiOrchestratorSearch: 'https://api.dev.atsone.com.br/one-api-orchestrator-search/',
  //urlApiOrchestratorSearch: "https://localhost:56893/",
  //urlApiPessoa: 'https://localhost:54984/',
  //urlApiEstoque: "https://localhost:50780/",
  //urlApiSpeech: "https://localhost:61615/",
  //urlApiFinanceiro: "https://localhost:54984/",
};
