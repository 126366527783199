import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-modal',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButtonModule
  ],
  templateUrl: './confirm-modal.component.html',
  styleUrl: './confirm-modal.component.css'
})
export class ConfirmModalComponent implements OnInit {
  public _title: string;
  public _description: string;
  public _buttonConfirm: string;
  public _buttonClose: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmModalComponent>,) {
  }

  ngOnInit(): void {
    this._title = this.data.title
    this._description = this.data.description
    this._buttonConfirm = this.data.buttonConfirm
    this._buttonClose = this.data.buttonClose
  }

  close() {
    this.dialogRef.close({
      confirm: false
    });
  }

  confirm() {
    this.dialogRef.close({
      confirm: true
    });
  }
}
