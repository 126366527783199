import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarStateService {
  private isExpanded = new BehaviorSubject<boolean>(this.getInitialState());

  navbarState$ = this.isExpanded.asObservable();

  constructor() {
    this.navbarState$.subscribe((state) => {
      localStorage.setItem('navbarState', JSON.stringify(state));
    });
    this.checkWindowSize();
    window.addEventListener('resize', this.checkWindowSize.bind(this));
  }

  getNavbarState(): boolean {
    return this.isExpanded.value;
  }

  setNavbarState(expanded: boolean): void {
    if (this.isMobile()) {
      this.isExpanded.next(false);
    } else {
      this.isExpanded.next(expanded);
    }
  }

  toggleNavbarState(): void {
    if (!this.isMobile()) {
      this.isExpanded.next(!this.isExpanded.value);
    }
  }

  private isMobile(): boolean {
    return window.innerWidth < 992;
  }

  private checkWindowSize(): void {
    if (this.isMobile()) {
      this.isExpanded.next(false);
    }
  }

  private getInitialState(): boolean {
    const savedState = localStorage.getItem('navbarState');
    return savedState !== null ? JSON.parse(savedState) : false;
  }
}
