import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpeechService } from '../../services/API/speech/speech.service';
import { SpeechToTextResponse } from '../../services/responses/speech/speech-to-text.response';
import { AlertService, AlertType } from '../../services/alert.service';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { OrchestratorSearchService } from '../../services/API/orchestrator-search/orchestrator-search.service';
import { SearchBarResponse } from '../../services/responses/orchestrator-search/search-bar.response';
import { Router } from '@angular/router';
import { SearchBarStruct } from '../../services/structs/orchestrator-search/search-bar.struct';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css'],
  imports: [FormsModule, ReactiveFormsModule, MatIconModule, CommonModule, MatAutocompleteModule],
  standalone: true,
})
export class SearchBarComponent {
  public isRecording: boolean = false;
  public mediaRecorder: MediaRecorder | null = null;
  public AlertType: AlertType;
  public audioStream: MediaStream | null = null;
  public audioChunks: Blob[] = [];
  public isSearchOpen: boolean = false;
  public searchText: string;
  private debounceTimeout: any;
  public _listSearchBarStruct:  (SearchBarStruct & { url: string })[] = [];
  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;
  @Input() isCompact: boolean = false;

  constructor(
    private speechService: SpeechService,
    private orchestratorSearchService: OrchestratorSearchService,
    private alertService: AlertService,
    private router: Router
  ) {}

  toggleSearch() {
    this.isSearchOpen = true;
    setTimeout(() => {
      this.searchInput?.nativeElement.focus();
    }, 0);
  }

  closeSearch() {
    this.isSearchOpen = false;
    this._listSearchBarStruct = [];
  }

  onMicClick() {
    if (this.isRecording) {
      this.stopRecording();
    } else {
      this.startRecording();
    }
  }

  private startRecording() {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        this.audioStream = stream;
        this.mediaRecorder = new MediaRecorder(stream, {
          mimeType: 'audio/webm;codecs=opus',
        });

        this.mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            this.audioChunks.push(event.data);
          }
        };

        this.mediaRecorder.onstop = () => {
          if (this.audioChunks.length > 0) {
            const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
            this.sendSpeech(audioBlob);
          }
          this.audioChunks = [];
        };

        this.mediaRecorder.start();
        this.isRecording = true;
      })
      .catch((error) => {
        console.error('Erro ao acessar o microfone:', error);
        this.alertService.show(
          'Erro ao acessar o microfone',
          'Certifique-se de ter concedido permissão para o uso do microfone.',
          AlertType.error
        );
      });
  }

  private stopRecording() {
    this.mediaRecorder?.stop();
    this.isRecording = false;
    this.audioStream?.getTracks().forEach((track) => track.stop());
  }

  private async blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  private sendSpeech(audioBlob: Blob) {
    this.blobToBase64(audioBlob).then((base64Audio) => {
      const base64Data = base64Audio.split(',')[1];

      this.speechService.sendBase64AudioToBackend(base64Data).subscribe({
        next: (response: SpeechToTextResponse) => {
          if (response.isError) {
            this.alertService.show(
              'Erro no reconhecimento de voz',
              response.errorDescription,
              AlertType.error
            );
            return;
          }

          if (response.transcript) {
            this.searchInput.nativeElement.value = response.transcript; // Atualiza o elemento de entrada
            this.onTextChange(response.transcript);
          } else {
            this.alertService.show(
              'Nenhum texto detectado',
              'Tente falar mais claramente.',
              AlertType.warning
            );
          }
        },
        error: (error) => {
          console.error('Erro ao enviar áudio:', error);
          this.alertService.show(
            'Erro inesperado',
            'Não foi possível processar o áudio.',
            AlertType.error
          );
        },
      });
    });
  }

  private GetResults(searchText: string) {
    this.orchestratorSearchService.Search(searchText).subscribe({
      next: (response: SearchBarResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro ao buscar resultados',
            response.errorDescription,
            AlertType.error
          );
          return;
        }
        if (response.directLink) {
          this.router.navigate([`/${response.directLink}`]);
          this.closeSearch();
        }
        else
        this._listSearchBarStruct = response.listSearchBarStruct.flatMap(item => item.listUrl.map(url => ({ ...item, url })));
      },
      error: (error) => {
        console.error('Erro na busca:', error);
        this.alertService.show(
          'Erro inesperado',
          'Não foi possível buscar os resultados.',
          AlertType.error
        );
      },
    });
  }

  selectValue(value: string){
    this.router.navigate([`/${value}`]);
    this.closeSearch();
  }

  onTextChange(value: string) {
    if (value && value != this.searchText)
    {
      this.searchText = value;
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
  
      this.debounceTimeout = setTimeout(() => {
        if (this.searchText) {
          this.GetResults(this.searchText);
        }}, 300);      
    }
  }
}
