import { Routes } from '@angular/router';
import { AuthComponent } from './auth/pages/auth/auth.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth-guard.service';
import { PasswordRecoveryComponent } from './auth/pages/password-recovery/password-recovery.component';
import { PasswordChangeComponent } from './auth/pages/password-change/password-change.component';
import { ListClientsComponent } from './client/pages/list-clients/list-clients.component';
import { ListSupplierComponent } from './supplier/pages/list-supplier/list-supplier.component';
import { SupplyRegisterComponent } from './supplier/pages/supply-register/supply-register.component';
import { ClientRegisterComponent } from './client/pages/client-register/client-register.component';
import { ListConfigComponent } from './config/pages/list-config/list-config.component';
import { AddUserComponent } from './config/pages/add-user/add-user.component';
import { AddEnterpriseComponent } from './config/pages/add-enterprise/add-enterprise.component';
import { AddBankComponent } from './config/pages/add-bank/add-bank.component';
import { InventoryListComponent } from './inventory/pages/inventory-list/inventory-list.component';
import { InventoryRegisterComponent } from './inventory/pages/inventory-register/inventory-register.component';
import { UserDataComponent } from './config/pages/user-data/user-data.component';
import { ListBillsPayComponent } from './financial/pages/list-bills-pay/list-bills-pay.component';
import { BillsPayRegisterComponent } from './financial/pages/bills-pay-register/bills-pay-register.component';
import { PDVComponent } from './vendas/pages/pdv.component';

export const routes: Routes = [
  // rotas sem auth
  {
    path: '',
    component: AuthComponent,
    pathMatch: 'full',
    data: { menu: false, search: false },
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { menu: true, search: false },
  },
  {
    path: 'password-recovery',
    component: PasswordRecoveryComponent,
    data: { menu: false, search: false },
  },
  {
    path: 'change-password/:token',
    component: PasswordChangeComponent,
    data: { menu: false, search: false },
  },
  // rotas com auth
  {
    path: 'venda',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'vendas',
        pathMatch: 'full',
      },
      {
        path: 'vendas',
        component: PDVComponent,
        data: { menu: true, search: true },
      },
    ],
  },
  // cliente
  {
    path: 'cliente',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'clientes',
        pathMatch: 'full',
      },
      {
        path: 'clientes',
        component: ListClientsComponent,
        data: { menu: true, search: true },
      },
      {
        path: 'clientes/novo',
        component: ClientRegisterComponent,
        data: { menu: true, search: true },
      },
      {
        path: 'clientes/editar/:idCliente',
        component: ClientRegisterComponent,
        data: { menu: true, search: true },
      },
    ],
  },
  // fornecedor
  {
    path: 'fornecedor',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'fornecedores',
        pathMatch: 'full',
      },
      {
        path: 'fornecedores',
        component: ListSupplierComponent,
        data: { menu: true, search: true },
      },
      {
        path: 'fornecedores/novo',
        component: SupplyRegisterComponent,
        data: { menu: true, search: true },
      },
      {
        path: 'fornecedores/editar/:idFornecedor',
        component: SupplyRegisterComponent,
        data: { menu: true, search: true },
      },
    ],
  },
  // estoque
  {
    path: 'estoque',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'produtos',
        pathMatch: 'full',
      },
      {
        path: 'produtos',
        component: InventoryListComponent,
        data: { menu: true, search: true },
      },
      {
        path: 'produtos/novo',
        component: InventoryRegisterComponent,
        data: { menu: true, search: true },
      },
      {
        path: 'produtos/editar/:idProduto',
        component: InventoryRegisterComponent,
        data: { menu: true, search: true },
      },
      {
        path: 'movimentacoes',
        component: InventoryRegisterComponent,
        data: { menu: true, search: true },
      },
    ],
  },
  // configs
  {
    path: 'config/list-config',
    component: ListConfigComponent,
    canActivate: [AuthGuard],
    data: { menu: true, search: true },
  },
  {
    path: 'config/add-user',
    component: AddUserComponent,
    canActivate: [AuthGuard],
    data: { menu: true, search: true },
  },
  {
    path: 'config/add-user/:idUsuario',
    component: AddUserComponent,
    canActivate: [AuthGuard],
    data: { menu: true, search: true },
  },
  {
    path: 'config/add-enterprise',
    component: AddEnterpriseComponent,
    canActivate: [AuthGuard],
    data: { menu: true, search: true },
  },
  {
    path: 'config/add-enterprise/:idEmpresa',
    component: AddEnterpriseComponent,
    canActivate: [AuthGuard],
    data: { menu: true, search: true },
  },
  {
    path: 'config/add-bank',
    component: AddBankComponent,
    canActivate: [AuthGuard],
    data: { menu: true, search: true },
  },
  {
    path: 'config/add-bank/:idBanco',
    component: AddBankComponent,
    canActivate: [AuthGuard],
    data: { menu: true, search: true },
  },
  {
    path: 'config/user-data/:idUsuario',
    component: UserDataComponent,
    canActivate: [AuthGuard],
    data: { menu: true, search: true },
  },

  //financeiro
  {
    path: 'financeiro',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'contas-pagar',
        pathMatch: 'full',
      },
      {
        path: 'contas-pagar',
        component: ListBillsPayComponent,
        data: { menu: true, search: true },
      },
      {
        path: 'contas-pagar/novo',
        component: BillsPayRegisterComponent,
        data: { menu: true, search: true },
      },
      {
        path: 'contas-pagar/editar/:idContasPagar',
        component: BillsPayRegisterComponent,
        data: { menu: true, search: true },
      },
    ],
  },
];
