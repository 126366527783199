import { Component, OnInit, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule, ViewportScroller } from '@angular/common';
import { DefaultColorEnum } from '../../shared/enum/default-color.enum';
import {AlertService, AlertType} from '../../shared/services/alert.service';
import { PessoaService } from '../../shared/services/API/pessoa/pessoa.service';
import { AuthService } from '../../shared/services/auth.service';
import { UserLoginResponse } from '../../shared/services/responses/auth/user-login.response';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { Router } from '@angular/router';
import { ClienteSearchRequest } from '../../shared/services/requests/pessoa/cliente-search.request';
import { ClienteSearchExhibitResponse } from '../../shared/services/responses/pessoa/cliente-search-exhibit.response';
import { DefaultProfilesEnum } from '../../shared/enum/default-profiles.enum';
import { debounceTime, Subject } from 'rxjs';
import { ConfirmModalComponent } from '../../shared/component/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ReturnStruct } from '../../shared/services/structs/return.struct';
import { SubheaderComponent } from '../../shared/subheader/subheader.component';
import { ExhibitComponent } from '../../shared/component/exhibit/exhibit.component';
import { ListExhibitStruct } from '../../shared/services/structs/list-exhibit.struct';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ExhibitEnum } from '../../shared/enum/exhibit.enum';
import { RoutesService } from '../../routes.service';
import { ViewSwitcherComponent } from '../../view-switcher/view-switcher.component';
import { SkeletonComponent } from '../../shared/skeleton/skeleton.component';

@Component({
  selector: 'app-pdv',
  standalone: true,
  imports: [
    MatIconModule,
    MatProgressSpinnerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SubheaderComponent,
    ExhibitComponent,
    MatFormFieldModule,
    MatSelectModule,
    ViewSwitcherComponent,
    SkeletonComponent,
  ],
  templateUrl: './pdv.component.html',
  styleUrl: './pdv.component.css',
})
export class PDVComponent implements OnInit {
  @ViewChild(ExhibitComponent) exhibit: ExhibitComponent;
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  searchSubject: Subject<string> = new Subject();
  searchDate: Subject<string> = new Subject();
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public pessoaService: PessoaService,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private viewportScroller: ViewportScroller,
    private routesService: RoutesService
  ) {  }
  public model: FormGroup;
  public color: string = DefaultColorEnum.Cor;
  public dataList: any[];
  public searchTimeout: any;
  public nameProfile: string = 'Cliente';
  public iconProfile: string = 'person';
  public profile: number = DefaultProfilesEnum.Cliente;
  public nameAdd: string = 'Cliente';
  public redirectUrl: string = this.routesService.CLIENT_REGISTER;
  public tableEnum: number = ExhibitEnum.Tabela;
  public display: ListExhibitStruct = new ListExhibitStruct();
  public listDisplay = this.display.listExhibit;
  public isTable: boolean = true;
  public selectedExhibit: number;
  public paymentMethod:string = 'cash';
  public receivedAmount:number = 0;
  public searchTerm: string;

  products = [
    { item: 1, id: 19880, name: 'Lápis Retrátil Prova D\'água Preto', quantity: 1, price: 19.35 },
    { item: 2, id: 25210, name: 'Kaiak Feminino Deo Corporal', quantity: 1, price: 6.65 },
    { item: 3, id: 19473, name: 'Creme Pentear Toin On Nature', quantity: 1, price: 9.08 },
    { item: 3, id: 19473, name: 'Creme Pentear Toin On Nature', quantity: 1, price: 9.08 },
    { item: 4, id: 19535, name: 'Faces Batom Color FPS8 Vinho', quantity: 1, price: 3.91 }
  ];


  ngAfterViewInit() {}

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      filterName: [''],
      filterDate: [''],
      currentPage: [0],
      totalPages: [0],
    });
    this.userLoginResponse = this.authService.getUserInfos();
    
  }

  searchProduct() {
  }

  increaseQuantity(product: any) {
    product.quantity++;
  }

  decreaseQuantity(product: any) {
    if (product.quantity > 1) {
      product.quantity--;
    }
  }

  calculateTotal() {
    return this.products.reduce((acc, product) => acc + product.quantity * product.price, 0);
  }

  calculateChange() {
    return this.receivedAmount - this.calculateTotal();
  }

  finalizeSale() {
    alert('Venda finalizada!');
  }
}
