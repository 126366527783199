import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { UserLoginResponse } from '../../../services/responses/auth/user-login.response';
import { DefaultColorEnum } from '../../../enum/default-color.enum';
import { PessoaService } from '../../../services/API/pessoa/pessoa.service';
import { PerfilResponse } from '../../../services/responses/pessoa/perfil.response';
import { AlertService, AlertType } from '../../../services/alert.service';
import { Perfil } from '../../../services/models/pessoa/perfil.model';
import { NavbarStateService } from '../../../../navbar-state.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-action-nav',
  standalone: true,
  imports: [CommonModule, RouterModule, MatCardModule, MatIconModule],
  templateUrl: './action-nav.component.html',
  styleUrl: './action-nav.component.css',
})
export class ActionNavComponent implements OnInit {
  @Output() showActionNavFalse = new EventEmitter<number>();
  @Output() toggleActionNav = new EventEmitter<void>();
  public color: string = DefaultColorEnum.Cor;
  public listPerfil: Perfil[];
  public modules: any[] = [];
  public accesColor: string = DefaultColorEnum.Acesso;
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  showActionNav: boolean = false;
  private subscription!: Subscription;
  currentRoute: string = '';

  constructor(
    public authService: AuthService,
    public pessoaService: PessoaService,
    public alertService: AlertService,
    private router: Router,
    private navbarStateService: NavbarStateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.subscription = this.navbarStateService.navbarState$.subscribe(
      (state: boolean) => {
        this.showActionNav = state;
      }
    );

    this.userLoginResponse = this.authService.getUserInfos();

    if (this.userLoginResponse.empresaLogada?.corSistema) {
      this.color = this.userLoginResponse.empresaLogada?.corSistema;
      this.changeColor();
    }
    this.getPerfil();
    this.router.events.subscribe(() => {
      this.currentRoute = this.router.url;
    });
  }

  isActive(route: string): boolean {
    let transformation: string = this.currentRoute.substring(1) + '/';
    return transformation.startsWith(route);
  }

  getPerfil() {
    this.pessoaService.GetPerfil().subscribe({
      next: (response: PerfilResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          return;
        }
        this.listPerfil = response.listPerfil;
        this.listPerfil = response.listPerfil.filter((perfil) =>
          this.userLoginResponse.empresaLogada.listIdPerfil.includes(
            perfil.idPerfil
          )
        );
        this.cardLogical();
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      },
    });
  }

  onCardClick(card: any): void {
    let filteredModule = this.userLoginResponse.empresaLogada.listIdPerfil.find(
      (module) => module === card.idPerfil
    );
    if (filteredModule) {
      this.router.navigate([card.redirect]);
    } else {
      this.alertService.show(
        'Erro!',
        'Você não possui acesso a este perfil',
        AlertType.error
      );
    }
  }

  cardLogical() {
    this.modules = this.listPerfil.map((modulo) => {
      return {
        ...modulo,
        corDeFundo: this.accesColor,
      };
    });
  }

  clickContainerOut() {
    this.showActionNavFalse.emit();
  }

  changeColor() {
    document.documentElement.style.setProperty('--main-color', this.color);
  }

  onToggleNav(): void {
    this.navbarStateService.toggleNavbarState();
    this.showActionNav = this.navbarStateService.getNavbarState();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
