export class ProdutoBaseRequest {
    idProduto?: number;
    nome: string;
    ean: string;
    ncm: string;
    idGrupo?: number;
    idSubgrupo?: number;
    idFornecedor?: number;
    descricao?: string;
    referencia?: string;
    localizacaoFisica?: string;
    codProduto?: string;
}