import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MenuComponent } from '../../../shared/component/menu/menu.component';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, of } from 'rxjs';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';
import {
  AlertService,
  AlertType,
} from '../../../shared/services/alert.service';
import { PessoaService } from '../../../shared/services/API/pessoa/pessoa.service';
import { AuthService } from '../../../shared/services/auth.service';
import { MaskService } from '../../../shared/services/mask.service';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { UtilService } from '../../../shared/services/util.service';
import { EmpresaRequest } from '../../../shared/services/requests/pessoa/empresa.request';
import { EmpresaResponse } from '../../../shared/services/responses/pessoa/empresa.response';
import { SubHeaderComponent } from '../../../shared/component/sub-header/sub-header.component';
import { RoutesService } from '../../../routes.service';

@Component({
  selector: 'app-add-enterprise',
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    SubHeaderComponent,
  ],
  templateUrl: './add-enterprise.component.html',
  styleUrl: './add-enterprise.component.css',
})
export class AddEnterpriseComponent implements OnInit {
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  private cepTimeout: any;
  public masks: any;
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public pessoaService: PessoaService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private router: Router,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    private routesService: RoutesService
  ) {}
  public imagePreview: string | ArrayBuffer | null = null;
  public model: FormGroup;
  public requestUpdate: EmpresaRequest = new EmpresaRequest();
  public color: string = DefaultColorEnum.Cor;
  public _idEnterprise: number | null = null;
  public dataList: any[];
  public nameProfile: string = 'Configurações';
  public iconProfile: string = 'settings';
  public profile: number = DefaultProfilesEnum.Configuração;
  @ViewChild('numeroEndereco') numeroEndereco: ElementRef;
  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      nome: ['', Validators.required],
      razaoSocial: [''],
      email: ['', Validators.required],
      cpfCnpj: ['', Validators.required],
      telefone: [''],
      telefone2: [''],
      nascimento: [''],
      cep: [''],
      logradouro: [''],
      cidade: [''],
      numero: [''],
      bairro: [''],
      complemento: [''],
      localidade: [''],
      uf: [''],
      pais: [''],
      base64LogoEmpresa: [''],
      corSistema: [''],
    });
    this.userLoginResponse = this.authService.getUserInfos();

    this.authService.permissao(this.profile, PapelEnum.lerGravar);

    if (this.userLoginResponse.empresaLogada?.corSistema) {
      this.color = this.userLoginResponse.empresaLogada?.corSistema;
      this.changeColor();
    }
    this.nameProfile =
      this.userLoginResponse.listPerfil.find((x) => x.idPerfil == this.profile)
        ?.nomePerfil ?? this.nameProfile;
    this.iconProfile =
      this.userLoginResponse.listPerfil.find((x) => x.idPerfil == this.profile)
        ?.googleIcone ?? this.iconProfile;
    let idEnterprise: number = this.activatedRoute.snapshot.params['idEmpresa'];
    if (idEnterprise) {
      this._idEnterprise = Number(idEnterprise);
      this.getEnterprise();
    }
    this.isLoading = false;
  }

  changeColor() {
    document.documentElement.style.setProperty('--main-color', this.color);
  }

  submit() {
    this.isLoading = true;
    if (this.model.invalid) {
      this.alertService.show(
        'Erro!',
        'Preencha os campos obrigatórios',
        AlertType.error
      );
      this.isLoading = false;
      return;
    } else if (this._idEnterprise) {
      if (this.model.get('nome')?.value)
        this.requestUpdate.nome = this.model.get('nome')?.value;
      if (this.model.get('razaoSocial')?.value)
        this.requestUpdate.razaoSocial = this.model.get('razaoSocial')?.value;
      if (this.model.get('email')?.value)
        this.requestUpdate.email = this.model.get('email')?.value;
      if (this.model.get('cpfCnpj')?.value)
        this.requestUpdate.cpfCnpj = this.model.get('cpfCnpj')?.value;
      if (this.model.get('telefone')?.value)
        this.requestUpdate.telefone = this.model.get('telefone')?.value;
      if (this.model.get('telefone2')?.value)
        this.requestUpdate.telefone2 = this.model.get('telefone2')?.value;
      if (this.model.get('nascimento')?.value)
        this.requestUpdate.nascimento = this.model.get('nascimento')?.value;
      if (this.model.get('latitude')?.value)
        this.requestUpdate.latitude = this.model.get('latitude')?.value;
      if (this.model.get('longitude')?.value)
        this.requestUpdate.longitude = this.model.get('longitude')?.value;
      if (this.model.get('cep')?.value)
        this.requestUpdate.cep = this.model.get('cep')?.value;
      if (this.model.get('logradouro')?.value)
        this.requestUpdate.logradouro = this.model.get('logradouro')?.value;
      if (this.model.get('cidade')?.value)
        this.requestUpdate.cidade = this.model.get('cidade')?.value;
      if (this.model.get('numero')?.value)
        this.requestUpdate.numero = this.model.get('numero')?.value;
      if (this.model.get('uf')?.value)
        this.requestUpdate.estado = this.model.get('uf')?.value;
      if (this.model.get('rua')?.value)
        this.requestUpdate.rua = this.model.get('rua')?.value;
      if (this.model.get('bairro')?.value)
        this.requestUpdate.bairro = this.model.get('bairro')?.value;
      if (this.model.get('complemento')?.value)
        this.requestUpdate.complemento = this.model.get('complemento')?.value;
      if (this.model.get('pais')?.value)
        this.requestUpdate.pais = this.model.get('pais')?.value;
      if (this.model.get('base64LogoEmpresa')?.value)
        this.requestUpdate.base64LogoEmpresa =
          this.model.get('base64LogoEmpresa')?.value;
      if (this.model.get('pais')?.value)
        this.requestUpdate.pais = this.model.get('pais')?.value;
      this.update();
    } else {
      const request = new EmpresaRequest();
      if (this.model.get('nome')?.value)
        request.nome = this.model.get('nome')?.value;
      if (this.model.get('razaoSocial')?.value)
        request.razaoSocial = this.model.get('razaoSocial')?.value;
      if (this.model.get('email')?.value)
        request.email = this.model.get('email')?.value;
      if (this.model.get('cpfCnpj')?.value)
        request.cpfCnpj = this.model.get('cpfCnpj')?.value;
      if (this.model.get('telefone')?.value)
        request.telefone = this.model.get('telefone')?.value;
      if (this.model.get('telefone2')?.value)
        request.telefone2 = this.model.get('telefone2')?.value;
      if (this.model.get('nascimento')?.value)
        request.nascimento = this.model.get('nascimento')?.value;
      if (this.model.get('latitude')?.value)
        request.latitude = this.model.get('latitude')?.value;
      if (this.model.get('longitude')?.value)
        request.longitude = this.model.get('longitude')?.value;
      if (this.model.get('cep')?.value)
        request.cep = this.model.get('cep')?.value;
      if (this.model.get('logradouro')?.value)
        request.logradouro = this.model.get('logradouro')?.value;
      if (this.model.get('cidade')?.value)
        request.cidade = this.model.get('cidade')?.value;
      if (this.model.get('numero')?.value)
        request.numero = this.model.get('numero')?.value;
      if (this.model.get('uf')?.value)
        request.estado = this.model.get('uf')?.value;
      if (this.model.get('rua')?.value)
        request.rua = this.model.get('rua')?.value;
      if (this.model.get('bairro')?.value)
        request.bairro = this.model.get('bairro')?.value;
      if (this.model.get('complemento')?.value)
        request.complemento = this.model.get('complemento')?.value;
      if (this.model.get('pais')?.value)
        request.pais = this.model.get('pais')?.value;
      if (this.model.get('base64LogoEmpresa')?.value)
        request.base64LogoEmpresa = this.model.get('base64LogoEmpresa')?.value;
      if (this.model.get('pais')?.value)
        request.pais = this.model.get('pais')?.value;
      request.idEmpresaMatriz = this.userLoginResponse.empresaLogada.idEmpresa;
      request.idGrupoEconomico =
        this.userLoginResponse.empresaLogada.idGrupoEconomico;
      this.register(request);
    }
  }

  register(enterprise: EmpresaRequest) {
    this.pessoaService.enterpriseRegister(enterprise).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Empresa registrada',
          AlertType.success
        );
        this.router.navigate([this.routesService.LIST_CLIENTS]);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  update() {
    this.pessoaService.enterpriseUpdate(this.requestUpdate).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Empresa atualizada',
          AlertType.success
        );
        this.router.navigate([this.routesService.LIST_CLIENTS]);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  getEnterprise() {
    this.pessoaService
      .searchEnterpriseById(Number(this._idEnterprise))
      .subscribe({
        next: (response: EmpresaResponse) => {
          if (response.isError) {
            this.alertService.show(
              'Erro inesperado',
              response.errorDescription,
              AlertType.error
            );
            this.isLoading = false;
            return;
          }
          this.requestUpdate.idPessoa = response.pessoa.idPessoa;
          this.requestUpdate.idEmpresa = response.empresa.idEmpresa;
          this.requestUpdate.idEmpresaMatriz = response.empresa.idEmpresaMatriz;
          this.requestUpdate.idGrupoEconomico =
            response.empresa.idGrupoEconomico;
          this.requestUpdate.idTipoEmpresa = response.empresa.idTipoEmpresa;
          this.model.get('nome')?.setValue(response.pessoa.nome);
          this.model.get('razaoSocial')?.setValue(response.pessoa.razaoSocial);
          this.model.get('email')?.setValue(response.pessoa.email);
          this.model.get('cpfCnpj')?.setValue(response.pessoa.cpfCnpj);
          this.model.get('telefone')?.setValue(response.pessoa.telefone);
          this.model.get('telefone2')?.setValue(response.pessoa.telefone2);
          this.model.get('nascimento')?.setValue(response.pessoa.nascimento);
          this.model.get('latitude')?.setValue(response.pessoa.latitude);
          this.model.get('longitude')?.setValue(response.pessoa.longitude);
          this.model.get('cep')?.setValue(response.pessoa.cep);
          this.model.get('logradouro')?.setValue(response.pessoa.logradouro);
          this.model.get('cidade')?.setValue(response.pessoa.cidade);
          this.model.get('numero')?.setValue(response.pessoa.numero);
          this.model.get('uf')?.setValue(response.pessoa.estado);
          this.model.get('rua')?.setValue(response.pessoa.logradouro);
          this.model.get('bairro')?.setValue(response.pessoa.bairro);
          this.model.get('complemento')?.setValue(response.pessoa.complemento);
          this.model.get('pais')?.setValue(response.pessoa.pais);
          this.model
            .get('base64LogoEmpresa')
            ?.setValue(response.empresa.urlLogoEmpresa);
          this.model.get('corSistema')?.setValue(response.empresa.corSistema);
          this.imagePreview = response.empresa.urlLogoEmpresa;
          this.isLoading = false;
        },
        error: (error) => {
          this.alertService.show(
            'Erro inesperado',
            'Erro ao buscar cep',
            AlertType.error
          );
          this.isLoading = false;
          return;
        },
      });
  }

  searchCep(event: any) {
    const cep = this.model.get('cep')?.value;
    if (this.cepTimeout) {
      clearTimeout(this.cepTimeout);
    }
    this.isLoading = true;
    this.model.get('logradouro')?.disable();
    this.model.get('complemento')?.disable();
    this.model.get('bairro')?.disable();
    this.model.get('uf')?.disable();
    this.model.get('pais')?.disable();
    this.model.get('cidade')?.disable();
    this.model.get('numero')?.disable();

    this.cepTimeout = setTimeout(() => {
      this.utilService
        .getAddressByCEP(cep)
        .pipe(
          catchError((error) => {
            this.alertService.show('Erro inesperado', error, AlertType.error);
            this.isLoading = false;
            this.model.get('logradouro')?.enable();
            this.model.get('complemento')?.enable();
            this.model.get('bairro')?.enable();
            this.model.get('uf')?.enable();
            this.model.get('pais')?.enable();
            this.model.get('cidade')?.enable();
            this.model.get('numero')?.enable();
            return of(null);
          })
        )
        .subscribe((data) => {
          if (data) {
            this.model.patchValue({
              logradouro: data.logradouro,
              complemento: data.complemento,
              bairro: data.bairro,
              cidade: data.localidade,
              uf: data.uf,
              pais: 'Brasil',
            });
          }

          this.model.get('logradouro')?.enable();
          this.model.get('complemento')?.enable();
          this.model.get('bairro')?.enable();
          this.model.get('uf')?.enable();
          this.model.get('pais')?.enable();
          this.model.get('cidade')?.enable();
          this.model.get('numero')?.enable();
          this.numeroEndereco.nativeElement.focus();
          this.isLoading = false;
        });
    }, 2000);
  }

  backPage() {
    this.router.navigate([this.routesService.LIST_CONFIG]);
  }

  onCpfCnpjInput(event: any): void {
    const value = event.target.value.replace(/\D/g, '');
    let formattedValue = '';

    if (value.length < 11) {
      formattedValue = value;
    } else if (value.length > 11) {
      formattedValue = this.maskService.applyMask(value, this.masks.cnpj);
    }

    this.model.get('cpfCnpj')?.setValue(formattedValue);
  }

  onTelefoneInput(event: any): void {
    const formattedTelefone = this.maskService.applyMask(
      event.target.value,
      this.masks.phone
    );
    this.model.get('telefone')?.setValue(formattedTelefone);
  }

  onSecondTelefoneInput(event: any): void {
    const formattedTelefone = this.maskService.applyMask(
      event.target.value,
      this.masks.phone
    );
    this.model.get('telefone2')?.setValue(formattedTelefone);
  }

  onCepInput(event: any): void {
    const formattedCep = this.maskService.applyMask(
      event.target.value,
      this.masks.cep
    );
    this.model.get('cep')?.setValue(formattedCep);
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        this.model.get('base64LogoEmpresa')?.setValue(base64String);
        this.imagePreview = base64String;
      };
      reader.readAsDataURL(file);
    }
  }
}
