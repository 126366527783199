<div *ngIf="!isLoading" class="table-container">
  <div class="hidden-columns-dropdown">
    <select (change)="showHiddenColumn($event)">
      <option value="" selected>Mostrar colunas ocultas</option>
      <option *ngFor="let col of hiddenColumns" [value]="col">
        {{ getColumnLabel(col) }}
      </option>
    </select>
  </div>
  <table>
    <thead>
      <!-- <tr cdkDropList cdkDragHandleDisabled (cdkDropListDropped)="reorderColumns($event)" cdkDropListOrientation="horizontal"> -->
      <tr>
        <!-- <th cdkDrag *ngFor="let column of columns" [hidden]="hiddenColumns.includes(column.key)" [cdkDragData]="column"> -->
        <th *ngFor="let column of columns" [hidden]="hiddenColumns.includes(column.key)">
          <div class="column-header">
            <div class="header-controls">
              <div class="flex-item">
                <span>{{ column.label }}</span>
                <mat-icon matTooltip="Clique para ocultar essa coluna" class="icon-button" (click)="toggleColumnVisibility(column.key)">visibility_off</mat-icon>
              </div>
              <div class="flex-item">
                <!-- <mat-icon matTooltip="Clique para ordenar os dados" class="icon-header" *ngIf="column.sortable" (click)="sortData(column.key)">{{ orderType === 'asc' ? 'arrow_upward' : 'arrow_downward' }}</mat-icon> -->
                <mat-icon matTooltip="Clique para ordenar os dados" class="icon-header" *ngIf="column.sortable">{{ orderType === 'asc' ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
                <mat-icon [matTooltip]="isColumnFiltered(column.key) ? 'Coluna filtrada' : 'Filtrar'" *ngIf="column.filterable" class="icon-header" [class.active-filter]="isColumnFiltered(column.key)" (click)="toggleFilterDropdown(column.key)">filter_alt</mat-icon>
                <!-- <mat-icon cdkDragHandle matTooltip="Segure e arraste para reordenar as colunas" class="icon-header">drag_indicator</mat-icon> -->
                <mat-icon matTooltip="Limpar filtro" *ngIf="column.filterable && isColumnFiltered(column.key)" class="icon-delete" (click)="onRemoveFilter(column.key)">
                  close
                </mat-icon>
              </div>
            </div>
            <div *ngIf="activeFilter === column.key" class="filter-dropdown">
              <input [value]="getFilterValue(column.key)" (input)="onFilter($event, column.key)" placeholder="Filtrar" />
            </div>
          </div>
        </th>
        <th>Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of filteredData">

        <td *ngFor="let column of columns" [hidden]="hiddenColumns.includes(column.key)">
          <span *ngIf="column.date; else normalCell">
            {{ formatDate(row[column.key]) }}
          </span>
          <ng-template #normalCell>
            {{ row[column.key] }}
          </ng-template>
        </td>
        <td class="wrapper-action">
          <mat-icon matTooltip="Visualizar" class="icon-action" (click)="view(row)">visibility</mat-icon>
          <mat-icon matTooltip="Editar" class="icon-action" (click)="edit(row)">edit</mat-icon>
          <mat-icon matTooltip="Excluir" class="icon-delete" (click)="remove(row)">delete</mat-icon>
        </td>
      </tr>
    </tbody>
  </table>
</div>