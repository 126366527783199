import { PessoaStruct } from "../../structs/pessoa/pessoa.struct";

export class EmpresaRequest extends PessoaStruct {
    public idTipoEmpresa: number;
    public expiracaoLogin: number;
    public corSistema: string;
    public base64LogoEmpresa: string;
    public timezone: number;
    public idEmpresaMatriz: number;
}
