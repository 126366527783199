import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DefaultColorEnum } from '../../enum/default-color.enum';
import { AuthService } from '../../services/auth.service';
import { UserLoginResponse } from '../../services/responses/auth/user-login.response';
import { DateFilterComponent } from '../filter-list/date-filter/date-filter.component';
import { OpenTextFilterComponent } from '../filter-list/open-text-filter/open-text-filter.component';

@Component({
  selector: 'app-exhibit',
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    CommonModule,
    MatIconModule,
    DateFilterComponent,
    OpenTextFilterComponent,
    FormsModule,
  ],
  templateUrl: './exhibit.component.html',
  styleUrl: './exhibit.component.css'
})
export class ExhibitComponent implements OnInit {
  public color: string = DefaultColorEnum.Cor;
  public userLoginResponse: UserLoginResponse;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder
  ) { }

  public model: FormGroup;
  @Input() data: any[] = [];
  @Input() isLoading: boolean = false;
  @Input() isTable: boolean = false;
  @Input() isPerson: boolean = false;
  @Input() isBill: boolean = false;
  @Output() valueChanged = new EventEmitter<any>();
  public isShowLoading: boolean = true;
  columns: string[] = [];
  sortedColumn: string = '';
  sortOrder: 'asc' | 'desc' = 'asc';
  filterText: string = '';
  emptyRows = Array(1);
  emptyColumns = Array(6);
  private debounceTimeout: any;
  contextMenuPosition = { x: 0, y: 0 };
  selectedColumn: string = '';

  filteredData: any[] = [];

  dataBack: any[] = [];

  ngOnInit(): void {
    this.userLoginResponse = this.authService.getUserInfos();
    this.model = this.formBuilder.group({
      searchText: [''],
    });

    if (this.userLoginResponse.empresaLogada?.corSistema) {
      this.color = this.userLoginResponse.empresaLogada?.corSistema;
      this.changeColor();
    }

    this.update();
  }

  changeColor() {
    document.documentElement.style.setProperty('--main-color', this.color);
  }

  update(): void {
    if (this.data && this.data.length > 0) {
      this.isShowLoading = false;
      this.filteredData = this.data;
      this.dataBack = this.filteredData;
    }
    else if (!this.data) {
      this.isShowLoading = false;
      this.columns = Array(1).fill('Sem dados');
    }
  }

  onDateSearchChange(value: any) {
    if (value != null) {
      this.valueChanged.emit({ action: 'dateSearch', dateSearch: value });
    }
    else {
      this.valueChanged.emit({ action: 'dateSearch' });
    }
  }

  edit(row: any) {
    this.valueChanged.emit({ action: 'edit', row: row });
  }

  remove(row: any) {
    this.valueChanged.emit({ action: 'remove', name: this.userLoginResponse.nomeUsuario, row: row });
  }

  sortData(column: string): void {
    if (this.sortedColumn === column) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortedColumn = column;
      this.sortOrder = 'asc';
    }

    this.filteredData.sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      if (aValue < bValue) return this.sortOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return this.sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
  }

  onFilterChange(value: string) {
    this.filterText = value;
    this.applyFilter();
  }

  applyFilter() {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      if (!this.filterText) {
        this.filteredData = this.data;
        this.isLoading = false;
        this.valueChanged.emit({ action: 'search', search: this.filterText });
      }
      else {
        this.valueChanged.emit({ action: 'search', search: this.filterText });
      }
    }, 300);
  }

  exportToPDF() {
    if (typeof window !== 'undefined' && typeof window.document !== 'undefined') {
      import('html2pdf.js').then(html2pdf => {

        const element = document.getElementById('tabela');

        if (this.filteredData.length <= 22) {
          const opt = {
            margin: 7,
            filename: 'tabela.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
              scale: 4,
              width: 940,
              height: 1350,
              x: 0,
              y: 0
            },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
          };
          html2pdf.default().from(element).set(opt).save();
        }

        else {
          const opt = {
            margin: 7,
            filename: 'tabela.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
              scale: 4,
              width: 940,
              height: 2100,
              x: 0,
              y: 0
            },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
          };
          html2pdf.default().from(element).set(opt).save();
        }

      }).catch(err => console.error('Erro ao carregar html2pdf.js', err));
    } else {
      console.error('html2pdf.js can only be used in the browser.');
    }
  }

  onRightClick(event: MouseEvent) {
    event.preventDefault();
    this.contextMenuPosition = { x: event.clientX, y: event.clientY };
    document.getElementById('contextMenu')!.style.display = 'block';
  }

  closeContextMenu() {
    if(document.getElementById('contextMenu')! != null)
    {
      document.getElementById('contextMenu')!.style.display = 'none';
    }
  }

  removeNulls() {
    this.filteredData = this.filteredData.filter(row => row[this.selectedColumn] !== null && row[this.selectedColumn] !== undefined && row[this.selectedColumn] !== '');
    this.closeContextMenu();
  }

  filterByValue() {
    const value = prompt("Digite o valor para filtrar:");
    if (value) {
      this.filteredData = this.filteredData.filter(row => row[this.selectedColumn] && row[this.selectedColumn].includes(value));
    }
    this.closeContextMenu();
  }

  filterByRange() {
    const minValue = prompt("Digite o valor mínimo:");
    const maxValue = prompt("Digite o valor máximo:");
    if (minValue && maxValue) {
      this.filteredData = this.filteredData.filter(row => row[this.selectedColumn] >= +minValue && row[this.selectedColumn] <= +maxValue);
    }
    this.closeContextMenu();
  }

  setSelectedColumn(column: string) {
    this.selectedColumn = column;
  }

  removeFilter() {
    this.filteredData = this.dataBack;
    this.closeContextMenu();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('#contextMenu')) {
      this.closeContextMenu();
    }
  }

}