<div class="main-container">
    <app-subheader [icon]="iconProfile" [title]="'Estoque'" [module]="'produtos'" [description]="_idProduto ? 'Edição de um produto existente' : 'Cadastro de um novo produto'" [isLoading]="isLoading" [isAddPage]="_idProduto ? false : true" [isEditPage]="_idProduto ? true : false"></app-subheader>
    <div class="container-separation">
        <ng-container *ngIf="_produtoResponse !== undefined">
            <ng-container *ngIf="_idProduto !== undefined">
                <h1 class="title-default">#{{_produtoResponse.produtoStruct.produto.idProduto}}
                    {{_produtoResponse.produtoStruct.produto.nome}}</h1>
            </ng-container>
            <ng-container *ngIf="_idProduto === undefined">
                <h1 class="title-default">Novo item no seu estoque</h1>
            </ng-container>

            <h2 class="subtitle-default">Dados basicos</h2>
            <form class="form" [formGroup]="model">
                <div class="row">
                    <div class="col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome do produto</mat-label>
                            <input matInput formControlName="productName" type="text">
                            <mat-error *ngIf="model.get('productName')?.invalid">
                                Informe o nome do produto
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Código ncm</mat-label>
                            <input
                              matInput
                              formControlName="nmc"
                              (ngModelChange)="getNcmValues($event)"
                              type="text"
                              [matAutocomplete]="auto"
                            />
                            <mat-autocomplete #auto="matAutocomplete">
                              <mat-option
                                *ngFor="let item of _listNcmSearchStruct"
                                (click)="selectNcmValue(item)"
                              >
                                {{ item.codigo }} - {{ item.descricao | slice: 0:15 }}...
                              </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="model.get('ncm')?.invalid">
                              Informe o código ncm do produto
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Código EAN</mat-label>
                            <input matInput formControlName="ean" type="text">
                            <mat-error *ngIf="model.get('ean')?.invalid">
                                Informe o código EAN do produto
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Código interno</mat-label>
                            <input matInput formControlName="productCode" type="text">
                            <mat-error *ngIf="model.get('productCode')?.invalid">
                                Informe o código do produto
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4" *ngIf="isViewSupply">
                        <mat-form-field appearance="outline">
                            <mat-label>Fornecedor</mat-label>
                            <mat-select formControlName="idFornecedor" (selectionChange)="onSelectChange($event)">
                                <mat-select-trigger>
                                    {{ selectedOption?.pessoa?.nome || 'Selecione um fornecedor' }}
                                </mat-select-trigger>

                                <input type="text" [formControl]="searchControl" placeholder="Pesquise um fornecedor..." class="input" />

                                <mat-option *ngIf="filteredFornecedores.length === 0" disabled> Nenhum fornecedor
                                    encontrado </mat-option>

                                <mat-option *ngFor="let fornecedor of filteredFornecedores" [value]="fornecedor.fornecedor.idFornecedor">
                                    {{ fornecedor.pessoa.nome }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4 bt-modal" *ngIf="isSupplyRegister">
                        <div class="change">
                            <button class="button-change btn-primary save" (click)="openSupplyModal()" [disabled]="isLoading">
                                <ng-container *ngIf="isLoading; else notLoading">
                                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                                </ng-container>
                                <ng-template #notLoading>
                                    Cadastrar Fornecedor
                                </ng-template>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="NgxEditor__Wrapper">
                    <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                    <ngx-editor [editor]="editor" formControlName="htmlDescription" [spellcheck]="true" [placeholder]="'Escreva aqui a melhor descrição para o seu produto! \nLEMBRE-SE: Uma boa descrição é capaz de vender melhor o seu produto'">
                    </ngx-editor>
                </div>

                <div class="row">
                </div>
                <div class="row">
                    <button type="submit" (click)="saveProductBase()" mat-flat-button class="btn-primary button-big">
                        <ng-container *ngIf="isLoading">
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                        </ng-container>
                        <ng-container *ngIf="!isLoading">
                            Salvar dados basicos
                        </ng-container>
                    </button>
                </div>
            </form>

            <ng-container *ngIf="_idProduto !== undefined">
                <div class="section">
                    <h2 class="subtitle-default">Imagens</h2>
                    <app-section-product-images (reloadProduct)="loadProduct()" [_produtoResponse]="_produtoResponse"></app-section-product-images>
                </div>
            </ng-container>
            <ng-container *ngIf="_idProduto !== undefined">
                <div class="section">
                    <h2 class="subtitle-default">Grade</h2>
                    <app-section-product-grid (reloadProduct)="loadProduct()" [_produtoResponse]="_produtoResponse"></app-section-product-grid>
                </div>
            </ng-container>
            <ng-container *ngIf="_idProduto === undefined">
                <div class="section section-disable">
                    <h2 class="subtitle-default">Imagens</h2>
                    <p>Salve os dados basicos antes para você poder incluir as imagens do seu produto</p>
                </div>

                <div class="section section-disable">
                    <h2 class="subtitle-default">Grade</h2>
                    <p>Salve os dados basicos antes para você poder incluir as grades do seu produto</p>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>