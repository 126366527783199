<div [ngClass]="{'sidebar-expanded': showActionNav, 'sidebar': !showActionNav}">
  <ng-container *ngFor="let module of modules; let isLast = last">
    <a *ngIf="!isLast" (click)="onCardClick(module)" class="menu-item" [ngClass]="{'active': isActive(module.redirect), 'highlight': module.corInvertida}">
      <div [ngClass]="{'flexbox-expanded': showActionNav, 'flexbox': !showActionNav}">
        <mat-icon class="menu-icon">
          {{ module.googleIcone }}
        </mat-icon>
        <div [ngClass]="{'text-expanded': showActionNav, 'text-collapsed': !showActionNav}">
          {{ module.nomePerfil }}
        </div>
      </div>
    </a>
    <div *ngIf="isLast" class="menu-item settings" [ngClass]="{'active': isActive(module.redirect)}">
      <a (click)="onCardClick(module)">
        <div [ngClass]="{'flexbox-expanded': showActionNav, 'flexbox': !showActionNav}">
          <mat-icon class="menu-icon">{{ module.googleIcone }}</mat-icon>
          <div [ngClass]="{'text-expanded': showActionNav, 'text-collapsed': !showActionNav}">
            {{ module.nomePerfil }}
          </div>
        </div>
      </a>
    </div>
  </ng-container>
  <a class="expand" (click)="onToggleNav()">
    <span class="icon-container">
      <mat-icon>{{ showActionNav ? 'chevron_left' : 'chevron_right' }}</mat-icon>
    </span>
  </a>
</div>