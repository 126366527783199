<div class="main-container">
    <app-subheader [icon]="'currency_exchange'" [title]="'Financeiro'" [module]="'contas-pagar'" [description]="'Sua base de clientes cadastrados'" [isLoading]="isLoading" [isAddPage]="false" [isEditPage]="false"></app-subheader>
    <app-view-switcher [selectedView]="selectedExhibit ? selectedExhibit : 1" (viewChanged)="onExhibitChange($event)"></app-view-switcher>
    <div class="card-filter">
        <form class="form" [formGroup]="model">
            <div class="filters">
                <div class="status">
                    <select id="idStatus" formControlName="idStatus" class="select" (disabled)="isLoading" (change)="onStatusChange()">
                        <option *ngFor="let status of listStatus" [value]="status.idStatusConta">
                            {{ status.nomeStatusConta }}
                        </option>
                    </select>
                </div>
            </div>
        </form>
    </div>
    <div class="container-separation">
        <div class="container-exhibit">
            <app-skeleton *ngIf="isLoading" variant="block" shape="square" width="100%" height="300px"></app-skeleton>
            <app-advanced-table [isLoading]="isLoading" [data]="dataList" [columns]="tableColumns" (valueChanged)="exhibitActions($event)"></app-advanced-table>
            <!-- <app-exhibit [data]="dataList" [isLoading]="isLoading" [isTable]="isTable" [isBill]="isBill"
                (valueChanged)="exhibitActions($event)"></app-exhibit> -->
        </div>
    </div>
    <div class="container-separation">
        <div class="pagination">
            <button class="pagination-btn" [disabled]="model.get('currentPage')?.value === 0" (click)="goToPreviousPage()">
                <mat-icon class="arrow">arrow_left</mat-icon>
            </button>

            <button class="pagination-btn" [disabled]="
            model.get('currentPage')?.value === model.get('totalPages')?.value
          " (click)="goToNextPage()">
                <mat-icon class="arrow">arrow_right</mat-icon>
            </button>
        </div>
        <div class="paginator">
            <span>{{ model.get("currentPage")?.value }} -
                {{ model.get("totalPages")?.value }}</span>
        </div>
    </div>
</div>