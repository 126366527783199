import { HttpClient } from "@angular/common/http";
import { CommonService } from "../../common-service";
import { Injectable } from "@angular/core";
import { catchError, Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { SearchBarResponse } from "../../responses/orchestrator-search/search-bar.response";

@Injectable({
  providedIn: 'root'
})
export class OrchestratorSearchService extends CommonService {

  constructor(
    private httpClient: HttpClient,
  ) {
    super();
  }
  
    public Search(request: string): Observable<SearchBarResponse> {
      return this.httpClient.get<SearchBarResponse>(environment.urlApiOrchestratorSearch + "PessoaSearchBar/searchText/" + request, this.addHeaderToken())
        .pipe(
          catchError(this.handleError)
        )
    }
}
