<div class="container">
  <div class="input">
    <mat-date-range-input [formGroup]="model" [rangePicker]="campaignOnePicker"
      [comparisonStart]="model.value.startDate" [comparisonEnd]="model.value.endDate">
      <input matStartDate placeholder="" formControlName="startDate">
      <input matEndDate placeholder="" formControlName="endDate">
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
    <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
    <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
  </div>
  <div class="icon">
    <mat-icon class="table-search-icon" (click)="mop()">event_busy</mat-icon>
  </div>
</div>