import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-view-switcher',
  templateUrl: './view-switcher.component.html',
  styleUrls: ['./view-switcher.component.css'],
  standalone: true,
  imports: [MatIconModule, CommonModule, MatTooltipModule],
})
export class ViewSwitcherComponent {
  @Input() views = [
    { id: 1, icon: 'table_chart', label: 'Visualização de tabela' },
    { id: 2, icon: 'grid_view', label: 'Visualização de cartões' },
  ];

  @Input() selectedView: number = 1;
  @Output() viewChanged = new EventEmitter<number>();

  selectView(view: any): void {
    if (this.selectedView !== view.id) {
      this.selectedView = view.id;
      this.viewChanged.emit(this.selectedView);
    }
  }
}
