import { IFilters } from '../pessoa/cliente-search.request';

export class ProdutoSearchRequest {
  public ativo: boolean;
  public searchText: string;
  public page: number;
  public dataInicio: Date;
  public dataFim: Date;
  public filters: IFilters[];
}
