import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { SpeechToTextResponse } from '../../responses/speech/speech-to-text.response';

@Injectable({
  providedIn: 'root'
})
export class SpeechService extends CommonService {

  constructor(
    private httpClient: HttpClient,
  ) {
    super();

  }

  public sendAudioToBackend(audioBlob: Blob): Observable<SpeechToTextResponse> {
    const formData = new FormData();
    formData.append('audioFile', audioBlob, 'audio.wav');
    return this.httpClient.post<SpeechToTextResponse>(
      environment.urlApiSpeech + "SpeechToText",
      formData, // Certifique-se de enviar o FormData, não o audioBlob diretamente
      this.addHeaderToken()
    )
      .pipe(
        catchError(this.handleError)
      )
  }
  public sendBase64AudioToBackend(base64Audio: string): Observable<SpeechToTextResponse> {
    const payload = { audioBase64: base64Audio };

    return this.httpClient.post<SpeechToTextResponse>(
      environment.urlApiSpeech + "SpeechToText",
      payload,
      this.addHeaderToken()
    ).pipe(
      catchError(this.handleError)
    );
  }


}