<div class="main-container">
    <app-subheader [icon]="iconProfile" [title]="'Estoque'" [module]="'produtos'" [description]="'Sua base de produtos cadastrados'" [isLoading]="isLoading" [isAddPage]="false" [isEditPage]="false"></app-subheader>
    <app-view-switcher [selectedView]="selectedExhibit ? selectedExhibit : 1" (viewChanged)="onExhibitChange($event)"></app-view-switcher>
    <!-- <div class="container-separation">
        <div class="card-filter">
            <div class="filters">
                <div class="exhibit">
                    <mat-form-field appearance="fill">
                        <mat-label>Exibição</mat-label>
                        <mat-select id="exhibit" [(value)]="selectedExhibit" (selectionChange)="onExhibitChange($event)" [disabled]="isLoading">
                            <mat-option *ngFor="let exhibit of listDisplay" [value]="exhibit.id">
                                <mat-icon>{{ exhibit.icon }}</mat-icon> {{ exhibit.nome }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div> -->
    <div class="container-separation">
        <div class="card-select">
            <div class="buttons">
                <button class="button-select select-card" [ngStyle]="buttonStyle" (click)="changeCard(tipoProduto)">Produtos</button>
            </div>
            <div class="buttons">
                <button class="button-select select-card" [ngStyle]="secondButtonStyle" (click)="changeCard(tipoMovimentacao)">Movimentação</button>
            </div>
        </div>
    </div>
    <div class="container-separation" *ngIf="isProduto">
        <div class="container-exhibit">
            <app-skeleton *ngIf="isLoading" variant="block" shape="square" width="100%" height="300px"></app-skeleton>
            <app-advanced-table [isLoading]="isLoading" [data]="dataList" [columns]="tableColumns" (valueChanged)="exhibitActions($event)"></app-advanced-table>
            <!-- <app-exhibit [data]="dataList" [isLoading]="isLoading" [isTable]="isTable" [isPerson]="false" (valueChanged)="exhibitActions($event)"></app-exhibit>
        </div>
        <!-- <button mat-stroked-button class="btn-see-more" (click)="nextPage()">
            Ver mais
            <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
    </div>
    <div class="container-separation" *ngIf="!isProduto">
        <cdk-accordion>
            <div class="accordion-header">
                <span class="accordion-text">EAN</span>
                <span class="accordion-text">NMC</span>
                <span class="accordion-text">NOME</span>
                <span class="accordion-text">ESTOQUE ATUAL</span>
                <span class="accordion-text in">ENTRADAS (Últimos 7 dias)</span>
                <span class="accordion-text out">SAIDAS (Últimos 7 dias)</span>
            </div>
            <div *ngFor="let mov of movimentacao.listMovimentacaoStruct; let i = index" cdkAccordionItem
                [expanded]="isExpanded(i)">
                <div class="accordion-header" (click)="toggleExpansion(i)">
                    <span class="accordion-text">{{ mov.produto.ean }}</span>
                    <span class="accordion-text">{{ mov.produto.nmc }}</span>
                    <span class="accordion-text">{{ mov.produto.nome }}</span>
                    <span class="accordion-text">{{ mov.subgrade ? mov.subgrade.quantidadeAtual : 0 }}</span>
                    <span class="accordion-text in">+ {{ mov.entrada }}</span>
                    <div class="final-accordion">
                        <span class="accordion-text out">- {{ mov.saida }}</span>
                        <span class="arrow" [class.open]="isExpanded(i)">▼</span>
                    </div>
                </div>

                <div class="accordion-body" *ngIf="isExpanded(i)">
                    <div class="movimentacoes">
                        <h4 class="accordion-title">Últimas Movimentações</h4>
                        <table>
                            <thead>
                                <tr>
                                    <th>Data</th>
                                    <th>Tipo de Movimentação</th>
                                    <th>Estoque Atual</th>
                                    <th>Estoque Anterior</th>
                                    <th>Movimentação</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of mov.listMovimentacaoEstoque">
                                    <td>{{ row.dataInclusao }}</td>
                                    <td>{{ row.tipoMovimentacao }}</td>
                                    <td>{{ row.quantidadeAtual }}</td>
                                    <td>{{ row.quantidadeAnterior }}</td>
                                    <td>{{ row.quantidadeMovimentada }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </cdk-accordion>-->

        </div>
    </div>