import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RoutesService {
  // Fixas
  readonly HOME = 'home';
  readonly PASSWORD_RECOVERY = 'password-recovery';
  readonly LIST_CLIENTS = 'cliente/clientes';
  readonly CLIENT_REGISTER = 'cliente/clientes/novo';
  readonly LIST_SUPPLIER = 'fornecedor/fornecedores';
  readonly SUPPLY_REGISTER = 'fornecedor/fornecedores/novo';
  readonly LIST_INVENTORY = 'estoque/produtos';
  readonly INVENTORY_REGISTER = 'estoque/produtos/novo';
  readonly LIST_CONFIG = 'config/list-config';
  readonly ADD_PERFIL_IMPOSTO = 'fiscal/perfil-imposto/novo';
  readonly ADD_USER = 'config/add-user';
  readonly ADD_ENTERPRISE = 'config/add-enterprise';
  readonly ADD_BANK = 'config/add-bank';
  readonly VENDAS = 'venda/vendas';
  readonly LIST_BILLS_PAY = 'financeiro/contas-pagar';
  readonly BILLS_PAY_REGISTER = 'financeiro/contas-pagar/novo';

  // Dinâmicas
  CHANGE_PASSWORD(token: string): string {
    return `change-password/${token}`;
  }

  CLIENT_UPDATE(id: string): string {
    return `cliente/clientes/editar/${id}`;
  }

  SUPPLY_UPDATE(id: string): string {
    return `fornecedor/fornecedores/editar/${id}`;
  }

  INVENTORY_UPDATE(id: string): string {
    return `estoque/produtos/editar/${id}`;
  }

  USER_UPDATE(id: string): string {
    return `config/add-user/${id}`;
  }

  ENTERPRISE_UPDATE(id: string): string {
    return `config/add-enterprise/${id}`;
  }

  BANK_UPDATE(id: string): string {
    return `config/add-bank/${id}`;
  }

  USERDATA_UPDATE(id: string): string {
    return `config/user-data/${id}`;
  }

  BILLS_PAY_UPDATE(id: string): string {
    return `financeiro/contas-pagar/editar/${id}`;
  }
}
