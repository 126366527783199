import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { Editor, NgxEditorModule, Toolbar } from 'ngx-editor';
import { RoutesService } from '../../../routes.service';
import { DefaultColorEnum } from '../../../shared/enum/default-color.enum';
import { DefaultProfilesEnum } from '../../../shared/enum/default-profiles.enum';
import { PapelEnum } from '../../../shared/enum/Papel/papel.enum';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { AuthService } from '../../../shared/services/auth.service';
import { MaskService } from '../../../shared/services/mask.service';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { SubheaderComponent } from '../../../shared/subheader/subheader.component';
import { FornecedorPessoaStruct } from '../../../shared/services/structs/pessoa/fornecedor-pessoa.struct';
import { MatSelectModule } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { SupplierRegisterModalComponent } from '../../../shared/component/supplier-register-modal/supplier-register-modal.component';
import { ListFornecedorResponse } from '../../../shared/services/responses/pessoa/list-fornecedor.response';
import { StatusConta } from '../../../shared/services/models/financeiro/status-conta.model';
import { ListCategoriaStructResponse } from '../../../shared/services/responses/financeiro/list-categoria-struct.response';
import { ListBancoEmpresaResponse } from '../../../shared/services/responses/financeiro/list-banco-empresa.response';
import { ContasPagarResponse } from '../../../shared/services/responses/financeiro/contas-pagar.response';
import { ContasPagarRequest } from '../../../shared/services/requests/financeiro/contas-pagar.request';
import { FinanceiroService } from '../../../shared/services/API/financeiro/financeiro.service';
import { PessoaService } from '../../../shared/services/API/pessoa/pessoa.service';
import { StatusContaResponse } from '../../../shared/services/responses/financeiro/status-conta.response';
import { BancoEmpresaSearchRequest } from '../../../shared/services/requests/financeiro/banco-empresa-search.request';
import { ListBancoEmpresaStructResponse } from '../../../shared/services/responses/financeiro/list-banco-empresa-struct.response';

@Component({
  selector: 'app-bills-pay-register',
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    SubheaderComponent,
    NgxEditorModule,
    MatSelectModule,
  ],
  templateUrl: './bills-pay-register.component.html',
  styleUrl: './bills-pay-register.component.css'
})
export class BillsPayRegisterComponent implements OnInit {
  public userLoginResponse: UserLoginResponse;
  public isLoading: boolean = true;
  searchControl: FormControl;
  filteredFornecedores: any[] = [];
  public masks: any;
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private router: Router,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    private routesService: RoutesService,
    public dialog: MatDialog,
    public financeiroService: FinanceiroService,
    public pessoaService: PessoaService,
  ) { }
  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  public model: FormGroup;
  public requestUpdate: ContasPagarRequest = new ContasPagarRequest();
  public color: string = DefaultColorEnum.Cor;
  public _idContasPagar: number | null = null;
  public nameProfile: string = 'Financeiro';
  public iconProfile: string = 'currency_exchange';
  public profile: number = DefaultProfilesEnum.Financeiro;
  public selectedOption: any;
  public listFornecedorPessoaStruct: FornecedorPessoaStruct[];
  public listStatus: StatusConta[];
  public listCategoriaStructResponse: ListCategoriaStructResponse = new ListCategoriaStructResponse();
  public listBancoEmpresaResponse: ListBancoEmpresaResponse = new ListBancoEmpresaResponse();
  public contasPagarResponse: ContasPagarResponse;
  public isUpdate: boolean = false;
  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      idContasPagar: [''],
      idFornecedor: ['', Validators.required],
      idSubCategoria: ['', Validators.required],
      vencimento: ['', Validators.required],
      previsaoPagamento: ['', Validators.required],
      valorConta: ['', Validators.required],
      idBancoEmpresa: ['', Validators.required],
      idStatusConta: ['', Validators.required],
      notaFiscal: ['', Validators.required],
      dataEmissao: [''],
      dataRegistro: [''],
      dataAlteracao: [''],
      observacoes: [''],
      nomeFornecedor: [''],
    });
    this.userLoginResponse = this.authService.getUserInfos();

    this.authService.permissao(this.profile, PapelEnum.lerGravar);

    if (this.userLoginResponse.empresaLogada?.corSistema) {
      this.color = this.userLoginResponse.empresaLogada?.corSistema;
      this.changeColor();
    }

    this.editor = new Editor();
    this.searchControl = new FormControl('');
    
    this.nameProfile =
      this.userLoginResponse.listPerfil.find((x) => x.idPerfil == this.profile)
        ?.nomePerfil ?? this.nameProfile;
    this.iconProfile =
      this.userLoginResponse.listPerfil.find((x) => x.idPerfil == this.profile)
        ?.googleIcone ?? this.iconProfile;
    let idContasPagar: number = this.activatedRoute.snapshot.params['idContasPagar'];
    if (idContasPagar) {
      this._idContasPagar = Number(idContasPagar);
      this.model.disable();
    }

    this.getSupplier();
    this.searchStatus();
    this.searchCategory();
    this.searchAccount();
    this.isLoading = false;
  }

  changeColor() {
    document.documentElement.style.setProperty('--main-color', this.color);
  }

  submit() {
    this.isLoading = true;
    if (this.model.invalid) {
      this.alertService.show(
        'Erro!',
        'Preencha os campos obrigatórios',
        AlertType.error
      );
      this.isLoading = false;
      return;
    } 
    else if (this._idContasPagar) {
      if (this.model.get('idFornecedor')?.value) {
        this.requestUpdate.idFornecedor = this.model.get('idFornecedor')?.value;
        var fornecedor = this.listFornecedorPessoaStruct.find(x => x.fornecedor.idFornecedor == this.model.get('idFornecedor')?.value)?.pessoa.nome;
        this.requestUpdate.nomeFornecedor = fornecedor != undefined ? fornecedor : '';
      }
      if (this.model.get('idSubCategoria')?.value)
        this.requestUpdate.idSubCategoria = this.model.get('idSubCategoria')?.value;
      if (this.model.get('vencimento')?.value)
        this.requestUpdate.vencimento = this.model.get('vencimento')?.value;
      if (this.model.get('previsaoPagamento')?.value)
        this.requestUpdate.previsaoPagamento = this.model.get('previsaoPagamento')?.value;
      if (this.model.get('valorConta')?.value)
        this.requestUpdate.valorConta = this.model.get('valorConta')?.value;
      if (this.model.get('idBancoEmpresa')?.value)
        this.requestUpdate.idBancoEmpresa = this.model.get('idBancoEmpresa')?.value;
      if (this.model.get('idStatusConta')?.value)
        this.requestUpdate.idStatusConta = this.model.get('idStatusConta')?.value;
      if (this.model.get('notaFiscal')?.value)
        this.requestUpdate.notaFiscal = this.model.get('notaFiscal')?.value;
      if (this.model.get('dataEmissao')?.value)
        this.requestUpdate.dataEmissao = this.model.get('dataEmissao')?.value;
      if (this.model.get('dataRegistro')?.value)
        this.requestUpdate.dataRegistro = this.model.get('dataRegistro')?.value;
      if (this.model.get('dataAlteracao')?.value)
        this.requestUpdate.dataAlteracao = this.model.get('dataAlteracao')?.value;
      if (this.model.get('observacoes')?.value)
        this.requestUpdate.observacoes = this.model.get('observacoes')?.value;
      this.update();
    } else {
      const request = new ContasPagarRequest();
      if (this.model.get('idFornecedor')?.value) {
        request.idFornecedor = this.model.get('idFornecedor')?.value;
        var fornecedor = this.listFornecedorPessoaStruct.find(x => x.fornecedor.idFornecedor == this.model.get('idFornecedor')?.value)?.pessoa.nome;
        request.nomeFornecedor = fornecedor != undefined ? fornecedor : '';
      }
      if (this.model.get('idSubCategoria')?.value)
        request.idSubCategoria = this.model.get('idSubCategoria')?.value;
      if (this.model.get('vencimento')?.value)
        request.vencimento = this.model.get('vencimento')?.value;
      if (this.model.get('previsaoPagamento')?.value)
        request.previsaoPagamento = this.model.get('previsaoPagamento')?.value;
      if (this.model.get('valorConta')?.value)
        request.valorConta = this.model.get('valorConta')?.value;
      if (this.model.get('idBancoEmpresa')?.value)
        request.idBancoEmpresa = this.model.get('idBancoEmpresa')?.value;
      if (this.model.get('idStatusConta')?.value)
        request.idStatusConta = this.model.get('idStatusConta')?.value;
      if (this.model.get('notaFiscal')?.value)
        request.notaFiscal = this.model.get('notaFiscal')?.value;
      if (this.model.get('dataEmissao')?.value)
        request.dataEmissao = this.model.get('dataEmissao')?.value;
      if (this.model.get('dataRegistro')?.value)
        request.dataRegistro = this.model.get('dataRegistro')?.value;
      if (this.model.get('dataAlteracao')?.value)
        request.dataAlteracao = this.model.get('dataAlteracao')?.value;
      if (this.model.get('observacoes')?.value)
        request.observacoes = this.model.get('observacoes')?.value;

      this.register(request);
    }
  }

  register(request: ContasPagarRequest) {
    this.financeiroService.billsPayRegister(request).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Conta a pagar registrada',
          AlertType.success
        );
        this.router.navigate([this.routesService.LIST_BILLS_PAY]);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  update() {
    this.financeiroService.billsPayUpdate(this.requestUpdate).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show(
          'Sucesso!',
          'Conta a pagar atualizada',
          AlertType.success
        );
        this.router.navigate([this.routesService.LIST_BILLS_PAY]);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      },
    });
  }

  getBillPay() {
    this.financeiroService.searchBillsPayById(Number(this._idContasPagar)).subscribe({
      next: (response: ContasPagarResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.isLoading = false;
          return;
        }
        this.requestUpdate.idContasPagar = response.contasPagar.idContasPagar;
        this.model.get('idFornecedor')?.setValue(response.contasPagar.idFornecedor);
        this.selectedOption = this.listFornecedorPessoaStruct.find(x => x.fornecedor.idFornecedor == response.contasPagar.idFornecedor);
        this.model.get('idSubCategoria')?.setValue(response.contasPagar.idSubCategoria);
        this.model.get('valorConta')?.setValue(response.contasPagar.valorConta);
        this.model.get('idBancoEmpresa')?.setValue(response.contasPagar.idBancoEmpresa);
        this.model.get('idStatusConta')?.setValue(response.contasPagar.idStatusConta);
        this.model.get('notaFiscal')?.setValue(response.contasPagar.notaFiscal);
        this.model.get('observacoes')?.setValue(response.contasPagar.observacoes);
        if (response.contasPagar.vencimento) {
          var date = response.contasPagar.vencimento.toString();
          date = date.split('T')[0];
          this.model.get('vencimento')?.setValue(date);
        }
        if (response.contasPagar.previsaoPagamento) {
          var date = response.contasPagar.previsaoPagamento.toString();
          date = date.split('T')[0];
          this.model.get('previsaoPagamento')?.setValue(date);
        }
        if (response.contasPagar.dataEmissao) {
          var date = response.contasPagar.dataEmissao.toString();
          date = date.split('T')[0];
          this.model.get('dataEmissao')?.setValue(date);
        }
        if (response.contasPagar.dataRegistro) {
          var date = response.contasPagar.dataRegistro.toString();
          date = date.split('T')[0];
          this.model.get('dataRegistro')?.setValue(date);
        }
        if (response.contasPagar.dataAlteracao) {
          var date = response.contasPagar.dataAlteracao.toString();
          date = date.split('T')[0];
          this.model.get('dataAlteracao')?.setValue(date);
        }
        this.model.enable();
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar cep',
          AlertType.error
        );
        this.isLoading = false;
        return;
      },
    });
  }

  backPage() {
    this.router.navigate([this.routesService.LIST_BILLS_PAY]);
  }

  getSupplier() {
    this.isLoading = true;
    this.model.get('idFornecedor')?.disable();
    this.pessoaService.searchSupplier().subscribe({
      next: (response: ListFornecedorResponse) => {
        if (response.isError) {
          this.alertService.show(
            'Erro inesperado',
            response.errorDescription,
            AlertType.error
          );
          this.model.get('idFornecedor')?.enable();
          this.isLoading = false;
          return;
        }
        this.listFornecedorPessoaStruct = response.listFornecedorPessoaStruct;
        this.filteredFornecedores = this.listFornecedorPessoaStruct;
        this.isLoading = false;
        if(this._idContasPagar)
        {
          this.getBillPay();
        }
        this.model.get('idFornecedor')?.enable();
      },
      error: (error) => {
        this.alertService.show(
          'Erro inesperado',
          'Erro ao buscar lista de fornecedores',
          AlertType.error
        );
        this.isLoading = false;
        this.model.get('idFornecedor')?.enable();
        return;
      },
    });
  }

  onSelectChange(event: any) {
    this.selectedOption = this.listFornecedorPessoaStruct.find(fornecedor => fornecedor.fornecedor.idFornecedor === event.value);
  }

  openSupplyModal() {
    const modalRef = this.dialog.open(SupplierRegisterModalComponent, {
      hasBackdrop: true,
      disableClose: false,
    });

    modalRef.afterClosed().subscribe(
      (result: { confirm: boolean; }) => {
        if (result && result.confirm) {
          this.getSupplier();
        }
        else {
          this.isLoading = false;
          return;
        }
      }
    );
  }

  searchStatus() 
    {
      this.isLoading = true;
      this.financeiroService.searchStatus().subscribe({
        next: (response: StatusContaResponse) => {
          if (response.isError) {
            this.alertService.show(
              'Erro inesperado',
              response.errorDescription,
              AlertType.error
            );
            return;
          }
          this.listStatus = response.listStatusConta;
          this.isLoading = false;
        },
        error: (error) => {
          this.alertService.show('Erro inesperado', error, AlertType.error);
          this.isLoading = false;
          return;
        },
      });
    }

    searchCategory()
    {
      this.isLoading = true;
      this.financeiroService.searchCategory().subscribe({
        next: (response: ListCategoriaStructResponse) => {
          if (response.isError) {
            this.alertService.show(
              'Erro inesperado',
              response.errorDescription,
              AlertType.error
            );
            return;
          }
          this.listCategoriaStructResponse = response;
          this.isLoading = false;
        },
        error: (error) => {
          this.alertService.show('Erro inesperado', error, AlertType.error);
          this.isLoading = false;
          return;
        },
      });
    }

    searchAccount()
    {
      this.financeiroService.searchBankEnterpriseList().subscribe({
                next: (response: ListBancoEmpresaResponse) => {
                  if (response.isError) {
                    this.alertService.show(
                      'Erro inesperado',
                      response.errorDescription,
                      AlertType.error
                    );
                    this.isLoading = false;
                    return;
                  }
                  this.listBancoEmpresaResponse = response;
                  this.isLoading = false;
                },
                error: (error) => {
                  this.alertService.show('Erro inesperado', error, AlertType.error);
                  this.isLoading = false;
                  return;
                },
              });
    }

}