import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
// import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserLoginResponse } from '../../services/responses/auth/user-login.response';
import { AuthService } from '../../services/auth.service';
import { debounceTime, Subject } from 'rxjs';
import { FilterService } from '../../../filters.service';
import { NavigationEnd, Router } from '@angular/router';

export interface Column {
  key: string;
  label: string;
  sortable: boolean;
  filterable: boolean;
  date: boolean;
  hidden?: boolean;
}

@Component({
  selector: 'app-advanced-table',
  standalone: true,
  imports: [
    CommonModule,
    // DragDropModule,
    MatIconModule,
    MatTooltipModule,
  ],
  templateUrl: './advanced-table.component.html',
  styleUrl: './advanced-table.component.css',
})
export class AdvancedTableComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() columns: Column[] = [];
  @Input() defaultOrder: { orderBy: string; orderType: 'asc' | 'desc' } = {
    orderBy: '',
    orderType: 'asc',
  };
  @Output() valueChanged = new EventEmitter<any>();
  @Input() isLoading: boolean = false;
  public userLoginResponse: UserLoginResponse;
  public isShowLoading: boolean = true;

  filteredData: any[] = [];
  orderBy: string = '';
  orderType: 'asc' | 'desc' = 'asc';
  hiddenColumns: string[] = [];
  activeFilter: string | null = null;
  private filterSubject = new Subject<{ value: string; key: string }>();
  private filterRemove = new Subject<{ key: string }>();

  constructor(
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private filterService: FilterService,
    private router: Router
  ) {
    this.filterSubject.pipe(debounceTime(300)).subscribe(({ value, key }) => {
      this.filterService.addFilter({ value, column: key });
      this.filterData(value, key);
    });
    this.filterRemove.pipe(debounceTime(300)).subscribe(({ key }) => {
      this.filterService.removeFilter(key);
      this.filterData('', key);
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.filterService.clearFilters();
      }
    });
  }

  ngOnInit() {
    this.userLoginResponse = this.authService.getUserInfos();
    this.filteredData = [...this.data];
    this.orderBy = this.defaultOrder.orderBy;
    this.orderType = this.defaultOrder.orderType;

    if (this.orderBy) {
      this.sortData(this.orderBy);
    }
    this.update();
  }

  ngOnChanges() {
    this.filteredData = [...this.data];
    this.cdr.detectChanges(); // Força a atualização da tabela
  }

  update(): void {
    if (this.data && this.data.length > 0) {
      this.isLoading = false;
      this.filteredData = this.data;
    }
  }

  toggleFilterDropdown(columnKey: string): void {
    this.activeFilter = this.activeFilter === columnKey ? null : columnKey;
  }

  sortData(columnKey: string) {
    if (this.orderBy === columnKey) {
      // Alterna entre asc e desc se a coluna for a mesma
      this.orderType = this.orderType === 'asc' ? 'desc' : 'asc';
    } else {
      // Define a nova coluna e ordem inicial como ascendente
      this.orderBy = columnKey;
      this.orderType = 'asc';
    }

    this.filteredData.sort((a, b) => {
      const valA = a[this.orderBy];
      const valB = b[this.orderBy];

      if (valA < valB) {
        return this.orderType === 'asc' ? -1 : 1;
      } else if (valA > valB) {
        return this.orderType === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  filterData(filter: string, key: string) {
    const filters = this.filterService.getFilters();
    this.valueChanged.emit({
      action: 'search',
      search: filter.toString().toLowerCase(),
      filters: filters,
    });
  }

  toggleColumnVisibility(columnKey: string) {
    if (this.hiddenColumns.includes(columnKey)) {
      this.hiddenColumns = this.hiddenColumns.filter(
        (key) => key !== columnKey
      );
    } else {
      this.hiddenColumns.push(columnKey);
    }
  }

  // reorderColumns(event: CdkDragDrop<Column[]>) {
  //   const previousIndex = this.columns
  //     .filter((column) => !this.hiddenColumns.includes(column.key))
  //     .indexOf(event.item.data);

  //   const visibleColumns = this.columns.filter(
  //     (column) => !this.hiddenColumns.includes(column.key)
  //   );

  //   const movedColumn = visibleColumns.splice(previousIndex, 1)[0];
  //   visibleColumns.splice(event.currentIndex, 0, movedColumn);

  //   // Atualiza a ordem geral de colunas respeitando as ocultas
  //   this.columns = visibleColumns.concat(
  //     this.columns.filter((column) => this.hiddenColumns.includes(column.key))
  //   );
  // }

  onFilter(event: Event, key: string) {
    const inputElement = event.target as HTMLInputElement;
    const filterValue = inputElement.value || '';
    this.filterSubject.next({ value: filterValue, key });
  }

  onRemoveFilter(key: string) {
    this.filterService.removeFilter(key);
    const filters = this.filterService.getFilters();
    this.valueChanged.emit({
      action: 'search',
      search: '',
      filters: [...filters],
    });
  }

  showHiddenColumn(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const columnKey = target.value;

    if (columnKey) {
      this.hiddenColumns = this.hiddenColumns.filter(
        (key) => key !== columnKey
      );
    }
  }

  getColumnLabel(columnKey: string): string | undefined {
    return this.columns.find((column) => column.key === columnKey)?.label;
  }

  formatDate(dateString: string): string {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  view(row: any) {
    this.valueChanged.emit({ action: 'view', row: row });
  }

  edit(row: any) {
    this.valueChanged.emit({ action: 'edit', row: row });
  }

  remove(row: any) {
    this.valueChanged.emit({
      action: 'remove',
      name: this.userLoginResponse.nomeUsuario,
      row: row,
    });
  }

  isColumnFiltered(columnKey: string): boolean {
    return this.filterService
      .getFilters()
      .some((filter) => filter.column === columnKey);
  }

  getFilterValue(columnKey: string): string {
    const filter = this.filterService
      .getFilters()
      .find((f) => f.column === columnKey);
    return filter ? filter.value : '';
  }
}
