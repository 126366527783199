import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { UserLoginResponse } from '../../../services/responses/auth/user-login.response';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AuthTokenService } from '../../../services/auth-token.service';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AlertService, AlertType } from '../../../services/alert.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { UtilService } from '../../../services/util.service';
import { Router } from '@angular/router';
import { RoutesService } from '../../../../routes.service';

@Component({
  selector: 'app-action-user',
  standalone: true,
  imports: [
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './action-user.component.html',
  styleUrl: './action-user.component.css',
})
export class ActionUserComponent implements OnInit {
  @Input() showActionUser: boolean;
  @Output() showActionUserFalse = new EventEmitter<number>();
  public userLoginResponse: UserLoginResponse;
  private ip: string = '192.168.0.2';
  private password: string = '******';
  public idEmpresaLogada: number;
  public model: FormGroup;
  public isLoading: boolean = false;
  public idUsuario: number;
  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private utilService: UtilService,
    public authTokenService: AuthTokenService,
    private router: Router,
    private routesService: RoutesService
  ) {}

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      enterpriseSelect: ['', [Validators.required]],
    });
    this.userLoginResponse = this.authService.getUserInfos();
    this.model
      .get('enterpriseSelect')
      ?.setValue(this.userLoginResponse.empresaLogada.idEmpresa);
    this.idUsuario = this.userLoginResponse.idUsuario;
    this.getIP();
  }

  clickContainerOut() {
    this.showActionUserFalse.emit();
  }

  clickLogout() {
    this.authService.logout();
  }

  changeEnterprise(event: any) {
    this.isLoading = true;
    this.idEmpresaLogada = this.model.get('enterpriseSelect')?.value;
    this.authTokenService
      .signIn(
        this.userLoginResponse.nomeUsuario,
        this.password,
        this.ip,
        this.idEmpresaLogada,
        this.userLoginResponse.token
      )
      .subscribe({
        next: (response: UserLoginResponse) => {
          if (response.isError) {
            this.alertService.show(
              'Erro inesperado',
              response.errorDescription,
              AlertType.error
            );
            this.isLoading = false;
            return;
          }

          this.authService.updateToken(response);
          this.isLoading = false;
          location.reload();
        },
        error: (error) => {
          this.alertService.show('Erro inesperado', error, AlertType.error);
          this.isLoading = false;
          return;
        },
      });
  }

  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      },
    });
  }

  redirectDataConfig() {
    this.router.navigate([
      this.routesService.USERDATA_UPDATE(String(this.idUsuario)),
    ]);
  }
}
