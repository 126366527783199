import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;

      if (error.status == 401) {
        sessionStorage.removeItem("token");
        window.location.href = '/';
      }
    }
    return throwError(errorMessage);
  };

  addHeaderToken() {
    let token: string = JSON.parse(sessionStorage.getItem('token') + "").token;

    let headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization': token
    }

    let requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return requestOptions;
  }
}
