export class NovaSubgradeStruct {
    public idSubgrade: number | null;

    public idGrade: number | null;

    public nomeSubgrade: string;

    public corHex: string | null;

    public precoAtual: number;

    public quantidadeAtual: number;
}