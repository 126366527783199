import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { filter } from 'rxjs/operators';
import { MenuComponent } from './shared/component/menu/menu.component';
import { ThemeService } from './theme.service';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    CommonModule,
    MenuComponent,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'one-ui';
  teste = environment.urlApiAuth;
  showMenu = false;
  showSearch = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private themeService: ThemeService) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        const currentRoute = this.getChildRoute(this.activatedRoute);
        this.showMenu = currentRoute.snapshot.data?.['menu'] || false;
        this.showSearch = currentRoute.snapshot.data?.['search'] || false;
      });
      this.themeService.loadTheme();
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  private getChildRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}