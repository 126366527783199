<div class="module-content header-separation">
    <div class="module-info">
        <div class="module">
            <div class="back-page">
                <mat-icon class="icon" (click)="returnPage()">arrow_back</mat-icon>
            </div>
            <div class="center-title">
                <div class="name-content">
                    <mat-icon class="icon">{{iconProfile}}</mat-icon>
                    <div class="perfil-nome">{{nameProfile}}</div>
                </div>
            </div>
        </div>
        <div class="module-action">
            <div class="button" *ngIf="showButtonAdd">
                <button type="submit" class="button-add btn-primary" (click)="redirect()">
                    <div class="button-content">
                        <ng-container *ngIf="isLoading; else notLoading">
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                        </ng-container>
                        <ng-template #notLoading>
                            <mat-icon class="add">add</mat-icon> <span class="name">{{nameAdd}}</span>
                        </ng-template>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>