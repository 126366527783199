<form [formGroup]="model" novalidate (ngSubmit)="submit()">
    <div formArrayName="listProdutoGradeStruct">
        <div *ngFor="let produtoGradeStruct of getListProdutoGradeStruct(model); let i = index">
            <div class="" [formGroupName]="i">

                <div class="row">
                    <div class="col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Grade</mat-label>
                            <input matInput formControlName="nomeGrade" type="text">
                            @if (getListProdutoGradeStruct(model)[i].get('nomeGrade')?.invalid) {
                            <mat-error>Informe o nome da grade</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        @if(getListProdutoGradeStruct(model).length > 1){
                        <button mat-fab class="btn-remove" (click)="removeListNovaSubgradeStruct(i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                        }
                        <button mat-fab class="btn-add" (click)="addListProdutoGradeStruct(null, i)">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>


                <div formArrayName="listNovaSubgradeStruct">
                    <div [formGroupName]="j"
                        *ngFor="let novaSubgradeStruct of getListNovaSubgradeStruct(produtoGradeStruct); let j = index">
                        <div class="row">
                            <div class="col-md-1">
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="outline">
                                    <mat-label>Subgrade</mat-label>
                                    <input matInput formControlName="nomeSubgrade" type="text">
                                    @if (getListNovaSubgradeStruct(produtoGradeStruct)[j].get('nomeSubgrade')?.invalid)
                                    {
                                    <mat-error>Informe a subgrade</mat-error>
                                    }
                                </mat-form-field>
                            </div>
                            <div class="col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>Cor</mat-label>
                                    <input matInput formControlName="corHex" type="color">
                                    <mat-icon matSuffix>palette</mat-icon>
                                    @if (getListProdutoGradeStruct(model)[i].get('corHex')?.invalid) {
                                    <mat-error>Informe o código da cor</mat-error>
                                    }
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="outline">
                                    <mat-label>Preço</mat-label>
                                    <input matInput formControlName="precoAtual" type="number">
                                    <mat-icon matSuffix>attach_money</mat-icon>
                                    @if (getListNovaSubgradeStruct(produtoGradeStruct)[j].get('precoAtual')?.invalid) {
                                    <mat-error>Informe o preço</mat-error>
                                    }
                                </mat-form-field>
                            </div>
                            <div class="col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>Qtde em estoque</mat-label>
                                    <input matInput formControlName="quantidadeAtual" type="number">
                                    @if
                                    (getListNovaSubgradeStruct(produtoGradeStruct)[j].get('quantidadeAtual')?.invalid) {
                                    <mat-error>Informe o quantidade atual no estoque</mat-error>
                                    }
                                </mat-form-field>
                            </div>

                            <div class="col-md-2">
                                @if(getListNovaSubgradeStruct(produtoGradeStruct).length > 1){
                                <button mat-fab class="btn-remove" (click)="removeNovaSubgradeStruct(i, j)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                                }

                                <button mat-fab class="btn-add" (click)="addListNovaSubgradeStruct(i, null, j)">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <button type="submit" mat-flat-button class="btn-primary button-big">
        @if (isLoading == true) {
        <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
        }
        @else{
        Salvar grade
        }
    </button>
    <br />
    <br />
    <br />
</form>