export class IFilters {
  column: string;
  value: string;
}

export class ClienteSearchRequest {
  public ativo: boolean;
  public searchText: string;
  public page: number;
  public dataInicio: Date;
  public dataFim: Date;
  public filters: IFilters[];
}
