import { ExhibitStruct } from "./exhibit.struct";

export class ListExhibitStruct {
  listExhibit: ExhibitStruct[];

  constructor() {
    this.listExhibit = [
      new ExhibitStruct(1, 'Tabela', 'table'),
      new ExhibitStruct(2, 'Card', 'dashboard')
    ]
  }
}