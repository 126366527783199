<div class="container container-auth">
    <div class="vertical-aling-out">
        <div class="vertical-aling-inner">
            <form class="form" [formGroup]="model" (ngSubmit)="post()" class="text-center">
                <img class="logo-auth" src="assets/images/atsone.png">
                <h1>Redefinir senha</h1>
                <p>
                    Insira seu email associado à sua conta para receber um link de redefinição de senha.
                </p>
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" type="email">
                    @if (model.get('email')?.invalid) {
                    <mat-error>Informe o seu e-mail cadastrado</mat-error>
                    }
                </mat-form-field>
                <button type="submit" mat-flat-button class="btn-primary button-big button-login">
                    @if (isLoading == true) {
                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                    }
                    @else{
                    Avançar
                    }
                </button>
                <button type="button" mat-button class="button-big button-login"
                    (click)="redirectLogin()">Voltar</button>
            </form>
        </div>
    </div>
</div>