import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './skeleton.component.html',
  styleUrl: './skeleton.component.css',
})
export class SkeletonComponent {
  @Input() variant: 'line' | 'block' = 'line';
  @Input() shape: 'square' | 'circle' | 'card' = 'square';
  @Input() width: string = '100%';
  @Input() height: string = '20px';
}
