<div class="main-container">
  <app-sub-header [showButtonAdd]="true" [redirectUrl]="redirectUrl" [nameProfile]="nameProfile" [iconProfile]="iconProfile" [nameAdd]="nameAdd" [isLoading]="isLoading">
  </app-sub-header>
  <div class="container-separation">
    <div class="card-setup">
      <div class="card-select">
        <div class="buttons">
          <button class="button-select select-card" [ngStyle]="buttonStyle" (click)="changeCard(tipoUsuarios)">Usuários</button>
        </div>
        <div class="buttons">
          <button class="button-select select-card" [ngStyle]="secondButtonStyle" (click)="changeCard(tipoBancos)">Bancos</button>
        </div>
        <div class="buttons">
          <button class="button-select select-card" [ngStyle]="thirdButtonStyle" (click)="changeCard(tipoEmpresa)">Empresa</button>
        </div>
      </div>
      <div class="card-filter">
        <form class="form" [formGroup]="model">
          <div class="filters">
            <div class="search">
              <app-open-text-filter (valueChanged)="onSearchValueChanged($event)"></app-open-text-filter>
            </div>
            <div class="status">
              <select id="statusSelect" formControlName="statusSelect" class="select" (disabled)="isLoading" (change)="onStatusChange($event)">
                <option *ngFor="let status of listStatus" [value]="status.id">
                  {{ status.nome }}
                </option>
              </select>
            </div>
            <div class="date">
              <app-date-filter (valueChanged)="onDateValueChanged($event)"></app-date-filter>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-list">
        <div class="row" *ngIf="!isLoading">
          <div class="usuarios" *ngIf="tipoCard == tipoUsuarios">
            <div class="col-12 col-md-6" *ngFor="let data of dataList">
              <div class="card-item">
                <div class="infos">
                  <span class="title"><b>{{data.nome}}</b></span>
                  <span class="title-4">{{data.email}}</span>
                  <span class="title-4">{{data.telefone}}</span>
                </div>

                <div class="actions">
                  <a>
                    <span class="material-symbols-outlined" aria-hidden="false" aria-label="Editar" (click)="redirect(data.idUsuario)">
                      arrow_forward
                    </span>
                  </a>
                </div>
                <div class="list-tags">
                  <div class="tags" *ngFor="let tag of getVisibleTags(data.tags).visibleTags">
                    <div class="tag-gray">
                      {{ tag }}
                    </div>
                  </div>
                </div>
                <div class="tags">
                  <div *ngIf="getVisibleTags(data.tags).remainingCount > 0" class="tag-gray">
                    +{{ getVisibleTags(data.tags).remainingCount }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="empresas" *ngIf="tipoCard == tipoEmpresa">
            <div class="col-12 col-md-6" *ngFor="let data of dataList">
              <div class="card-item">
                <div class="infos">
                  <span class="title">{{ data.nome }}</span>
                  <span class="title-4">{{ data.cnpj }}</span>
                  <span class="title-4">{{ data.email }}</span>
                  <span class="title-4">{{ data.telefone }}</span>
                  <span class="title-4" *ngIf="data.ativo">Ativo</span>
                  <span class="title-4" *ngIf="!data.ativo">Inativo</span>
                </div>

                <div class="actions">
                  <a>
                    <span class="material-symbols-outlined" aria-hidden="false" aria-label="Editar" (click)="redirect(data.idEmpresa)">
                      arrow_forward
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="bancos" *ngIf="tipoCard == tipoBancos">
            <div class="col-12 col-md-6" *ngFor="let data of dataList">
              <div class="card-item">
                <div class="infos">
                  <span class="title"><b>{{data.banco.nome}}</b></span>
                  <span class="title-4">{{data.banco.numBanco}}</span>
                </div>

                <div class="actions">
                  <a>
                    <span class="material-symbols-outlined" aria-hidden="false" aria-label="Editar" (click)="redirect(data.banco.idBanco)">
                      arrow_forward
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="isLoading">
          <div class="col-12 col-sm-6">
            <div class="card-item">
              <div class="infos">
                <app-loading-box [height]="'23'" [width]="'110'" [marginTop]="'0'"></app-loading-box>
                <app-loading-box [height]="'20'" [width]="'250'" [marginTop]="'10'"></app-loading-box>
                <app-loading-box [height]="'15'" [width]="'80'" [marginTop]="'10'"></app-loading-box>
              </div>
              <div class="actions"></div>
              <div class="tags">
                <app-loading-box [height]="'15'" [width]="'120'" [marginTop]="'8'"></app-loading-box>
              </div>
            </div>
          </div>
        </div>
        <div class="row" class="empty-list" *ngIf="!isLoading && dataList.length == 0">
          <div class="col-12 col-sm-6">
            <div class="result">Nenhum resultado encontrado</div>
            <img class="empty" src="/assets/images/empty-list.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-separation">
    <div class="pagination">
      <button class="pagination-btn" [disabled]="model.get('currentPage')?.value === 1" (click)="goToPreviousPage()">
        <mat-icon class="arrow">arrow_left</mat-icon>
      </button>
      <button class="pagination-btn" [disabled]="
          model.get('currentPage')?.value === model.get('totalPages')?.value
        " (click)="goToNextPage()">
        <mat-icon class="arrow">arrow_right</mat-icon>
      </button>
    </div>
    <div class="paginator">
      <span>{{ model.get("currentPage")?.value }} -
        {{ model.get("totalPages")?.value }}</span>
    </div>
  </div>
</div>