export class ContasPagarRequest {
    public idContasPagar: number;
    public idFornecedor: number;
    public idBancoEmpresa: number;
    public idSubCategoria: number;
    public idStatusConta: number;
    public valorConta: number;
    public nomeFornecedor: string;
    public notaFiscal: string;
    public observacoes?: string;
    public vencimento: Date;
    public previsaoPagamento: Date;
    public dataEmissao: Date;
    public dataRegistro: Date;
    public dataAlteracao: Date;
}
