import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { PerfilResponse } from '../../responses/pessoa/perfil.response';
import { ClienteSearchRequest } from '../../requests/pessoa/cliente-search.request';
import { ClienteSearchResponse } from '../../responses/pessoa/cliente-search.response';
import { ClienteRequest } from '../../requests/pessoa/cliente.request';
import { ReturnStruct } from '../../structs/return.struct';
import { ClienteSearchExhibitResponse} from '../../responses/pessoa/cliente-search-exhibit.response';
import { FornecedorSearchRequest } from '../../requests/pessoa/fornecedor-search.request';
import { FornecedorSearchExhibitResponse} from '../../responses/pessoa/fornecedor-search-exhibit.response';
import { FornecedorRequest } from '../../requests/pessoa/fornecedor.request';
import { ClienteResponse } from '../../responses/pessoa/cliente.response';
import { UsuarioRequest } from '../../requests/pessoa/usuario.request';
import { UsuarioResponse } from '../../responses/pessoa/usuario.response';
import { EmpresaResponse } from '../../responses/pessoa/empresa.response';
import { EmpresaRequest } from '../../requests/pessoa/empresa.request';
import { ListUsuarioResponse } from '../../responses/pessoa/list-usuario.response';
import { ListEmpresaResponse } from '../../responses/pessoa/list-empresa.response';
import { UsuarioSearchRequest } from '../../requests/pessoa/usuario-search.request';
import { EmpresaSearchRequest } from '../../requests/pessoa/empresa-search.request';
import { FornecedorResponse } from '../../responses/pessoa/fornecedor.response';
import { ChangePasswordRequest } from '../../requests/pessoa/change-password.request';
import { ListFornecedorResponse } from '../../responses/pessoa/list-fornecedor.response';
import { ListClienteStructResponse } from '../../responses/pessoa/list-cliente-struct.response';

@Injectable({
  providedIn: 'root'
})
export class PessoaService extends CommonService {

  constructor(
    private httpClient: HttpClient,
  ) {
    super();
  }

  public GetPerfil(): Observable<PerfilResponse> {
    return this.httpClient.get<PerfilResponse>(environment.urlApiPessoa + "Perfil/", this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchClient(clienteSearchRequest: ClienteSearchRequest): Observable<ClienteSearchResponse> {

    return this.httpClient.post<ClienteSearchResponse>(environment.urlApiPessoa + "ClienteSearch", clienteSearchRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchList(): Observable<ListClienteStructResponse> {

    return this.httpClient.get<ListClienteStructResponse>(environment.urlApiPessoa + "Cliente", this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchClientById(idCliente: number): Observable<ClienteResponse> {

    return this.httpClient.get<ClienteResponse>(environment.urlApiPessoa + "Cliente/idCliente/" + idCliente, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchClientExhibit(clienteSearchRequest: ClienteSearchRequest): Observable<ClienteSearchExhibitResponse> {

    return this.httpClient.post<ClienteSearchExhibitResponse>(environment.urlApiPessoa + "ClienteSearch/Exhibit", clienteSearchRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public clientRegister(clienteRequest: ClienteRequest): Observable<ReturnStruct> {
    return this.httpClient.post<ReturnStruct>(environment.urlApiPessoa + "Cliente", clienteRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public clientUpdate(clienteRequest: ClienteRequest): Observable<ReturnStruct> {
    return this.httpClient.put<ReturnStruct>(environment.urlApiPessoa + "Cliente", clienteRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public removeClientById(idCliente: number): Observable<ReturnStruct> {

    return this.httpClient.delete<ReturnStruct>(environment.urlApiPessoa + "Cliente/idCliente/" + idCliente, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchSupplier(): Observable<ListFornecedorResponse> {

    return this.httpClient.get<ListFornecedorResponse>(environment.urlApiPessoa + "FornecedorSearch/", this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchSupplierExhibit(fornecedorSearchRequest: FornecedorSearchRequest): Observable<FornecedorSearchExhibitResponse> {

    return this.httpClient.post<FornecedorSearchExhibitResponse>(environment.urlApiPessoa + "FornecedorSearch/Exhibit", fornecedorSearchRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchSupplierById(idSupplier: number): Observable<FornecedorResponse> {

    return this.httpClient.get<FornecedorResponse>(environment.urlApiPessoa + "Fornecedor/idFornecedor/" + idSupplier, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public removeSupplierById(idFornecedor: number): Observable<ReturnStruct> {

    return this.httpClient.delete<ReturnStruct>(environment.urlApiPessoa + "Fornecedor/idFornecedor/" + idFornecedor, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public supplyRegister(clienteRequest: FornecedorRequest): Observable<ReturnStruct> {
    return this.httpClient.post<ReturnStruct>(environment.urlApiPessoa + "Fornecedor", clienteRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public supplyUpdate(clienteRequest: FornecedorRequest): Observable<ReturnStruct> {
    return this.httpClient.put<ReturnStruct>(environment.urlApiPessoa + "Fornecedor", clienteRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchUser(usuarioSearchRequest: UsuarioSearchRequest): Observable<ListUsuarioResponse> {

    return this.httpClient.post<ListUsuarioResponse>(environment.urlApiPessoa + "Usuario/Lista/", usuarioSearchRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchUserById(idUser: number): Observable<UsuarioResponse> {

    return this.httpClient.get<UsuarioResponse>(environment.urlApiPessoa + "Usuario/idUsuario/" + idUser, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public userRegister(usuarioRequest: UsuarioRequest): Observable<ReturnStruct> {
    return this.httpClient.post<ReturnStruct>(environment.urlApiPessoa + "Usuario", usuarioRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public userUpdate(usuarioRequest: UsuarioRequest): Observable<ReturnStruct> {
    return this.httpClient.put<ReturnStruct>(environment.urlApiPessoa + "Usuario", usuarioRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public changePassword(changePasswordRequest: ChangePasswordRequest): Observable<ReturnStruct> {
    return this.httpClient.post<ReturnStruct>(environment.urlApiPessoa + "Usuario/changePassword/idUsuario/" + changePasswordRequest.idUsuario, changePasswordRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchEnterprise(empresaSearchRequest: EmpresaSearchRequest): Observable<ListEmpresaResponse> {

    return this.httpClient.post<ListEmpresaResponse>(environment.urlApiPessoa + "Empresa/Lista", empresaSearchRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchEnterpriseById(idEnterprise: number): Observable<EmpresaResponse> {

    return this.httpClient.get<EmpresaResponse>(environment.urlApiPessoa + "Empresa/idEmpresa/" + idEnterprise, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public enterpriseRegister(empresaRequest: EmpresaRequest): Observable<ReturnStruct> {
    return this.httpClient.post<ReturnStruct>(environment.urlApiPessoa + "Empresa", empresaRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public enterpriseUpdate(empresaRequest: EmpresaRequest): Observable<ReturnStruct> {
    return this.httpClient.put<ReturnStruct>(environment.urlApiPessoa + "Empresa", empresaRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
