import { Injectable } from '@angular/core';
export interface Filter {
  column: string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  private filters: Filter[] = [];

  getFilters(): Filter[] {
    return [...this.filters];
  }

  addFilter(newFilter: Filter): void {
    const existingFilterIndex = this.filters.findIndex(
      (filter) => filter.column === newFilter.column
    );

    if (existingFilterIndex !== -1) {
      this.filters[existingFilterIndex] = newFilter;
    } else {
      this.filters.push(newFilter);
    }
  }

  removeFilter(column: string): void {
    this.filters = this.filters.filter((filter) => filter.column !== column);
  }

  clearFilters(): void {
    this.filters = [];
  }
}
