<div class="main-container">
    <app-sub-header [showButtonAdd]="false" [redirectUrl]="''" [nameProfile]="nameProfile" [iconProfile]="iconProfile" [nameAdd]="''" [isLoading]="isLoading">
    </app-sub-header>
    <div class="container-separation">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="grid">
                <div class="section">
                    <div class="title-section">
                        <h2>DADOS DO BANCO</h2>
                    </div>
                    <div class="form-grid">
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome Do Banco</mat-label>
                                <input matInput id="nomeBanco" formControlName="nomeBanco" type="text" placeholder="Nome Do Banco" class="field" />
                                <mat-error *ngIf="model.get('nomeBanco')?.touched && model.get('nomeBanco')?.invalid">
                                    O campo Nome Do Banco é obrigatório.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Número Do Banco</mat-label>
                                <input matInput id="numBanco" formControlName="numBanco" type="text" maxlength="3" placeholder="Número Do Banco" class="field" (input)="onInput($event, 3)" />
                                <mat-error *ngIf="model.get('numBanco')?.touched && model.get('numBanco')?.invalid">
                                    O campo Número Do Banco é obrigatório.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>

            <div formArrayName="contas">
                <div *ngFor="let conta of contas.controls; let i = index">
                    <div [formGroupName]="i" class="grid">
                        <div class="section">
                            <div class="title-section">
                                <h2>DADOS DA AGÊNCIA</h2>
                            </div>
                            <div class="form-grid">
                                <div class="form-group">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Número Agência</mat-label>
                                        <input matInput formControlName="numAgencia" type="text" maxlength="5" placeholder="Número da Agência" class="field" (input)="onInput($event, 5)" />
                                    </mat-form-field>
                                </div>
                                <div class="form-group">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Nome da Agência</mat-label>
                                        <input matInput formControlName="nomeAgencia" type="text" placeholder="Nome da Agência" class="field" />
                                    </mat-form-field>
                                </div>
                                <div class="form-group">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Conta</mat-label>
                                        <input matInput formControlName="conta" type="text" maxlength="12" placeholder="Número da Conta" class="field" (input)="onInput($event, 12)" />
                                    </mat-form-field>
                                </div>
                                <div class="form-group">
                                    <mat-form-field appearance="outline">
                                      <mat-label>Digito</mat-label>
                                      <input matInput formControlName="digito" type="text" placeholder="Digito verificador" class="field" maxlength="2" (input)="onInput($event, 2)" />
                                      <mat-error *ngIf="model.get('digito')?.touched && model.get('digito')?.invalid">
                                        O campo Digito é obrigatório.
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                <div class="form-group">
                                    <div class="last-field">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Limite</mat-label>
                                            <input matInput formControlName="limite" type="number" placeholder="Limite" class="field" />
                                        </mat-form-field>
                                        <div class="add-account-icon">
                                            <mat-icon (click)="addConta()">add</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="button-display">
        <div class="submit">
            <button class="button btn-primary save" (click)="submit()" [disabled]="isLoading">
                <ng-container *ngIf="isLoading; else notLoading">
                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                </ng-container>
                <ng-template #notLoading> Salvar </ng-template>
            </button>

            <button class="button cancel" (click)="backPage()">Cancelar</button>
        </div>
    </div>
</div>