<div class="main-container">
    <app-subheader [icon]="'currency_exchange'" [title]="'Contas a Pagar'" [module]="'Financeiro'"
        [description]="_idContasPagar !== null ? 'Edição de uma conta a pagar' : 'Cadastro de uma nova conta a pagar'"
        [isLoading]="isLoading" [isAddPage]="_idContasPagar !== null ? false : true"
        [isEditPage]="_idContasPagar !== null ? true : false"></app-subheader>
    <div class="container-separation">
        <form class="form" *ngIf="model" [formGroup]="model">
            <div class="grid">
                <div class="section">
                    <div class="title-section">
                        <h2>DADOS DA CONTA A PAGAR</h2>
                    </div>
                    <div class="form-grid">
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Fornecedor</mat-label>
                                <mat-select formControlName="idFornecedor" (selectionChange)="onSelectChange($event)">
                                    <mat-select-trigger>
                                        {{ selectedOption?.pessoa?.nome || 'Selecione um fornecedor' }}
                                    </mat-select-trigger>

                                    <input type="text" [formControl]="searchControl"
                                        placeholder="Pesquise um fornecedor..." class="input" />

                                    <mat-option *ngIf="filteredFornecedores.length === 0" disabled> Nenhum fornecedor
                                        encontrado </mat-option>

                                    <mat-option *ngFor="let fornecedor of filteredFornecedores"
                                        [value]="fornecedor.fornecedor.idFornecedor">
                                        {{ fornecedor.pessoa.nome }}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="model.get('idFornecedor')?.touched && model.get('idFornecedor')?.invalid">
                                    O campo Fornecedor é obrigatório.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <div class="change">
                                <button class="button-change btn-primary save" (click)="openSupplyModal()"
                                    [disabled]="isLoading">
                                    <ng-container *ngIf="isLoading; else notLoading">
                                        <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                                    </ng-container>
                                    <ng-template #notLoading>
                                        Cadastrar Fornecedor
                                    </ng-template>
                                </button>
                            </div>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Vencimento</mat-label>
                                <input matInput id="vencimento" formControlName="vencimento" type="date"
                                    class="field" />
                                <mat-error *ngIf="model.get('vencimento')?.touched && model.get('vencimento')?.invalid">
                                    O campo Vencimento é obrigatório.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <div class="last-field">
                                <mat-form-field appearance="outline">
                                    <mat-label>Valor Da Conta</mat-label>
                                    <input matInput formControlName="valorConta" type="number"
                                        placeholder="Valor Da Conta" class="field" />
                                </mat-form-field>
                                <mat-error *ngIf="model.get('valorConta')?.touched && model.get('valorConta')?.invalid">
                                    O campo Valor Da Conta é obrigatório.
                                </mat-error>
                            </div>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Status Conta</mat-label>
                                <mat-select formControlName="idStatusConta" (disabled)="isLoading">

                                    <mat-option *ngFor="let status of listStatus" [value]="status.idStatusConta">
                                        {{ status.nomeStatusConta }}
                                    </mat-option>

                                </mat-select>
                                <mat-error
                                    *ngIf="model.get('idStatusConta')?.touched && model.get('idStatusConta')?.invalid">
                                    O campo Status Conta é obrigatório.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Categoria</mat-label>
                                <mat-select formControlName="idSubCategoria" (disabled)="isLoading">
                                    <div class="category-select"
                                        *ngFor="let categoria of listCategoriaStructResponse.listCategoriaStruct">
                                        <span class="title-select">{{ categoria.categoria.nomeCategoria }}</span>
                                        <mat-option *ngFor="let subCategoria of categoria.listSubCategoria"
                                            [value]="subCategoria.idSubCategoria">
                                            {{ subCategoria.nomeSubCategoria }}
                                        </mat-option>
                                    </div>
                                </mat-select>
                                <mat-error
                                    *ngIf="model.get('idSubCategoria')?.touched && model.get('idSubCategoria')?.invalid">
                                    O campo Categoria é obrigatório.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Previsão De Pagamento</mat-label>
                                <input matInput id="previsaoPagamento" formControlName="previsaoPagamento" type="date"
                                    class="field" />
                                <mat-error
                                    *ngIf="model.get('previsaoPagamento')?.touched && model.get('previsaoPagamento')?.invalid">
                                    O campo Previsão De Pagamento é obrigatório.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Conta Corrente</mat-label>
                                <mat-select formControlName="idBancoEmpresa">
                                    <mat-option *ngFor="let bancoEmpresa of listBancoEmpresaResponse.listBancoEmpresa"
                                        [value]="bancoEmpresa.idBancoEmpresa">
                                        {{ bancoEmpresa.nomeAgencia }}
                                    </mat-option>
                                    <mat-error
                                        *ngIf="model.get('idBancoEmpresa')?.touched && model.get('idBancoEmpresa')?.invalid">
                                        O campo Conta Corrente é obrigatório.
                                    </mat-error>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="title-section">
                        <h2>DETALHES</h2>
                    </div>
                    <div class="form-grid">
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Nota Fiscal</mat-label>
                                <input matInput id="notaFiscal" formControlName="notaFiscal" type="text"
                                    class="field" />
                                <mat-error *ngIf="model.get('notaFiscal')?.touched && model.get('notaFiscal')?.invalid">
                                    O campo Nota Fiscal é obrigatório.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Data Emissão</mat-label>
                                <input matInput id="dataEmissao" formControlName="dataEmissao" type="date"
                                    class="field" />
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Data Registro</mat-label>
                                <input matInput id="dataRegistro" formControlName="dataRegistro" type="date"
                                    class="field" />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="section" *ngIf="model">
                    <div class="title-section">
                        <h2>OBSERVAÇÕES</h2>
                    </div>
                    <div class="NgxEditor__Wrapper">
                        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                        <ngx-editor [editor]="editor" formControlName="observacoes" [spellcheck]="true"
                            [placeholder]="'Observacoes'">
                        </ngx-editor>
                    </div>
                </div>
                <div class="section" *ngIf="isLoading && isUpdate">
                    <div class="title-section">
                        <h2>INFORMAÇÕES ADICIONAIS</h2>
                    </div>
                    <div class="sub-section">
                        <div class="last-edit">
                            <span class="last-edit-title">Última Alteração</span>
                            <span class="last-edit-text">{{contasPagarResponse.contasPagar.nomeUsuarioAlteracao}}</span>
                        </div>
                        <div class="include">
                            <span class="include-title">Inclusão</span>
                            <span class="include-text">{{contasPagarResponse.contasPagar.nomeUsuarioRegistro}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="button-display">
        <div class="submit">
            <button class="button btn-primary save" (click)="submit()" [disabled]="isLoading">
                <ng-container *ngIf="isLoading; else notLoading">
                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                </ng-container>
                <ng-template #notLoading>
                    Salvar
                </ng-template>
            </button>

            <button class="button cancel" (click)="backPage()">Cancelar</button>
        </div>
    </div>
</div>