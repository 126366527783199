import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subheader',
  standalone: true,
  imports: [MatIconModule, MatProgressSpinnerModule],
  templateUrl: './subheader.component.html',
  styleUrl: './subheader.component.css',
})
export class SubheaderComponent {
  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() module: string = '';
  @Input() description: string = '';
  @Input() isLoading: boolean = false;
  @Input() isAddPage: boolean = false;
  @Input() isEditPage: boolean = false;

  constructor(private router: Router) {}

  navigateTo(path: string): void {
    this.router.navigate([path.toLocaleLowerCase()]);
  }
}
