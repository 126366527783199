<div [ngClass]="{'hidden': isCompact, 'search-container': !isCompact}">
  <div class="search-bar">
    <input
      #searchInput
      type="text"
      class="search-input"
      (ngModelChange)="onTextChange($event)"
      [(ngModel)]="searchText"
      [matAutocomplete]="auto"
      placeholder="Faça sua busca"
      autofocus
    />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let item of _listSearchBarStruct" (click)="selectValue(item.url)">
        <div class="itemName">{{ item.nome }}</div>
        <div class="itemEmail">{{ item.email | slice: 0:30 }}</div>
        
        <!-- Aqui exibe a URL associada a cada item -->
        <div class="itemLinks">
            {{ item.url.split('/')[1] }}
        </div>
      </mat-option>
    </mat-autocomplete>
    <button class="mic-button" (click)="onMicClick()" [class.active]="isRecording">
      <mat-icon>{{ isRecording ? 'mic_off' : 'mic' }}</mat-icon>
    </button>
  </div>
</div>

<div [ngClass]="{'hidden': !isCompact, 'mobile-search-container': isCompact}">
  <button class="floating-search-button" (click)="toggleSearch()">
    <mat-icon>search</mat-icon>
  </button>

  <div class="overlay" *ngIf="isSearchOpen" (click)="closeSearch()">
    <div class="mobile-search-bar" (click)="$event.stopPropagation()">
      <input
        #searchInput
        type="text"
        class="search-input"
        (ngModelChange)="onTextChange($event)"
        [(ngModel)]="searchText" 
        [matAutocomplete]="auto"
        placeholder="Faça sua busca"
        autofocus
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let item of _listSearchBarStruct" (click)="selectValue(item.url)">
          <div class="itemName">{{ item.nome }}</div>
          <div class="itemEmail">{{ item.email | slice: 0:30 }}</div>
          
          <!-- Aqui exibe a URL associada a cada item -->
          <div class="itemLinks">
              {{ item.url.split('/')[1] }}
          </div>
        </mat-option>
      </mat-autocomplete>
      <button class="mic-button" (click)="onMicClick()" [class.active]="isRecording" >
        <mat-icon>{{ isRecording ? 'mic_off' : 'mic' }}</mat-icon>
      </button>
    </div>
  </div>
</div>

