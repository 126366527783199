import { PapelStruct } from "./papel.struct";

export class ListPapelStruct {
  listPapel: PapelStruct[];

  constructor() {
    this.listPapel = [
      new PapelStruct(1, 'Ler'),
      new PapelStruct(2, 'Ler E Gravar'),
      new PapelStruct(3, 'Controle Total')
    ]
  }
}