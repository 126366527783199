import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import { AuthService } from '../../services/auth.service';
import { ActionNavComponent } from './action-nav/action-nav.component';
import { ActionUserComponent } from './action-user/action-user.component';
import { ActionNotificationComponent } from './action-notification/action-notification.component';
import { DefaultColorEnum } from '../../enum/default-color.enum';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { NavbarStateService } from '../../../navbar-state.service';
import { Subscription } from 'rxjs';
import { ThemeService } from '../../../theme.service';
import { RoutesService } from '../../../routes.service';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SearchBarComponent,
    MatBadgeModule,
    ActionNavComponent,
    ActionUserComponent,
    ActionNotificationComponent,
    MatTooltipModule,
  ],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.css',
})
export class MenuComponent implements OnInit {
  @Input() showSearch: boolean = true;
  public showActionUser: boolean = false;
  public showActionNotification: boolean = false;
  public areThereNotifications: boolean = false;
  public isFactory: boolean = false;
  public isConsult: boolean = false;
  public empresaLogo: string = '';
  public isStore: boolean = false;
  public color: string = DefaultColorEnum.Cor;
  showActionNav: boolean = false;
  private subscription!: Subscription;
  currentTheme: 'light' | 'dark' = 'light';

  constructor(
    private authService: AuthService,
    private router: Router,
    private navbarStateService: NavbarStateService,
    private themeService: ThemeService,
    private routesService: RoutesService
  ) {}

  ngOnInit(): void {
    this.subscription = this.navbarStateService.navbarState$.subscribe(
      (state: boolean) => {
        this.showActionNav = state;
      }
    );

    let userLoginResponse = this.authService.getUserInfos();
    if (userLoginResponse.empresaLogada?.corSistema) {
      this.color = userLoginResponse.empresaLogada?.corSistema;
      this.changeColor();
    }
    if (
      userLoginResponse.empresaLogada?.urlLogoEmpresa &&
      userLoginResponse.empresaLogada?.urlLogoEmpresa.length > 0
    ) {
      this.empresaLogo = userLoginResponse.empresaLogada?.urlLogoEmpresa;
    } else {
      this.empresaLogo = '/assets/images/atsone.png';
    }

    this.currentTheme = this.themeService.getTheme();
  }

  toggleTheme() {
    this.themeService.toggleTheme();
    this.currentTheme = this.themeService.getTheme();
  }

  clickShowActionUser(action: any) {
    if (action == null) {
      this.showActionUser = !this.showActionUser;
      return;
    }

    this.showActionUser = action;
  }

  clickRerirectConfig() {
    this.router.navigate([this.routesService.LIST_CONFIG]);
  }

  toggleNavbar(): void {
    this.navbarStateService.toggleNavbarState();
    this.showActionNav = this.navbarStateService.getNavbarState();
  }

  clickRedirectHome() {
    this.router.navigate([this.routesService.HOME]);
  }
  clickShowActionNotification(action: any) {
    if (action == null) {
      this.showActionNotification = !this.showActionNotification;
      return;
    }

    this.showActionNotification = action;
  }

  setAreThereNotifications(areThereNotifications: boolean) {
    this.areThereNotifications = areThereNotifications;
  }

  changeColor() {
    document.documentElement.style.setProperty('--main-color', this.color);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
