<div class="camera-dialog">
    <div class="camera-body">
        <video *ngIf="!showCapturedImage" #video class="camera-video"></video>
        <img *ngIf="showCapturedImage" [src]="capturedImages[capturedImages.length - 1]" class="captured-image" />
        <canvas #canvas class="camera-canvas"></canvas>
      </div>
    <div class="camera-footer">
        <button class="btn-primary mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-unthemed mat-mdc-button-base" (click)="closeCamera()">Cancelar</button>
        <button style="width: 60px; height: 60px; border-radius: 60px; background-color: white; position:fixed; margin-top: -100px;" (click)="captureImages()"></button>
    </div>
  </div>