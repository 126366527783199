import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { EmptyListComponent } from '../../empty-list/empty-list.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '../../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { UserLoginResponse } from '../../../services/responses/auth/user-login.response';
import { DefaultColorEnum } from '../../../enum/default-color.enum';

@Component({
  selector: 'app-action-notification',
  standalone: true,
  imports: [
    MatButtonModule,
    EmptyListComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
  ],
  templateUrl: './action-notification.component.html',
  styleUrl: './action-notification.component.css'
})
export class ActionNotificationComponent implements OnInit {
  constructor(
    private authService: AuthService,
    public dialog: MatDialog,) {
  }

  @Input() showActionNotification: boolean;
  @Input() areThereNotifications: boolean;

  @Output() showActionNotificationFalse = new EventEmitter<number>()
  @Output() setAreThereNotifications = new EventEmitter<boolean>()
  public color: string = DefaultColorEnum.Cor;
  public userLoginResponse: UserLoginResponse;

  ngOnInit(): void {
    this.userLoginResponse = this.authService.getUserInfos();
    if (this.userLoginResponse.empresaLogada?.corSistema) {
      this.color = this.userLoginResponse.empresaLogada?.corSistema;
      this.changeColor();
    }
  }

  clickContainerOut() {
    this.showActionNotificationFalse.emit();
  }

  clickLogout() {
    this.authService.logout();
  }

  changeColor() {
    document.documentElement.style.setProperty('--main-color', this.color);
  }

}

