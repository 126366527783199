import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.response';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../../shared/services/auth.service';
@Component({
  selector: 'app-select-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatFormFieldModule,
    CommonModule
  ],
  templateUrl: './select-modal.component.html',
  styleUrl: './select-modal.component.css'
})
export class SelectModalComponent implements OnInit {
  public userLoginResponse: UserLoginResponse;
  constructor(
    private dialogRef: MatDialogRef<SelectModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserLoginResponse,
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) {
    this.dialogRef.backdropClick().subscribe(() => {
      this.close(false, this.idEmpresaLogada);
    });
  }
  public model: FormGroup;
  public isLoading: boolean = false;
  public idEmpresaLogada: number;

  ngOnInit(): void {
    this.userLoginResponse = this.authService.getUserInfos()
    this.model = this.formBuilder.group({
      enterpriseSelect: ['', [Validators.required]]
    });
  }

  submit() {
    if (this.model.invalid || this.isLoading)
      return;

    this.isLoading = true;

    this.idEmpresaLogada = this.model.get('enterpriseSelect')?.value

    this.isLoading = false;

    this.close(true, this.idEmpresaLogada);
  }

  close(response: boolean, idEmpresaLogada: number): void {
    const result = { success: response, idEmpresaLogada: idEmpresaLogada };
    this.dialogRef.close(result);
  }
}
